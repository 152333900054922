import apiPrefix from 'middleware/helpers/apiPrefix';

export default class ChangeOrderDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchDataByFacilities(facilitiesIds, dateFrom, dateTo) {
    if (this.data.length > 0) {
      return this.data;
    }
    console.log(
      'ChangeOrderDataSource: fetching change orders by facilities...'
    );
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const facilityUrl = `/api/facility/{${facilitiesIds}}`;
    const retailCashUrl = '/retail-cash';
    const dateFromToUrl = `/${dateFrom}/${dateTo}`;
    const changeOrderUrl = '/change-order';

    this.url =
      apiPrefix + facilityUrl + retailCashUrl + dateFromToUrl + changeOrderUrl;
    const resp = await fetch(this.url, opts);

    if (!resp.ok) {
      return null;
    }
    const data = await resp.text();
    const jsonData = JSON.parse(
      data.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')
    );
    this.data = jsonData;
    return jsonData;
  }

  async fetchData(dateFrom, dateTo) {
    if (this.data.length > 0) {
      return this.data;
    }
    console.log('ChangeOrderDataSource: fetching change orders...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };

    const retailCashUrl = '/retail-cash';
    const dateFromToUrl = `/${dateFrom}/${dateTo}`;
    const changeOrderUrl = '/change-order';

    this.url = apiPrefix + retailCashUrl + dateFromToUrl + changeOrderUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.text();
    const jsonData = JSON.parse(
      data.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')
    );
    this.data = jsonData;
    return jsonData;
  }
}
