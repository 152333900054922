import React from 'react';

import { HeadingPrimaryMD } from '@warbyparker/design-type';
import BillFoldIcon from 'containers/icons/billfold_icon.png';
import AuthModal from 'containers/layout/components/AuthModal';
import { Grid } from '@material-ui/core';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      retailStores: this.props.retailStores,
      permissions: this.props.permissions,
    };

    if (this.props.retailStores.length > 1) this.state.open = false;
  }
  render() {
    return (
      <Grid>
        <HeadingPrimaryMD>
          Welcome to Billfold!{' '}
          <img alt="Billfold Icon" src={BillFoldIcon} width="50" height="32" />
        </HeadingPrimaryMD>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
      </Grid>
    );
  }
}

export default LandingPage;
