export default class TillCountItemDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }

  async insertItem(item) {
    // console.log('TillCountItemDataSource: inserting till count item...');
    const domain = process.env.OHM_DOMAIN;
    const baseUrl = `https://${domain}/retail-cash/till-denomination-log`;
    const changeOrderIdUrl = `/${item.till_count_id}`;
    const typeIdUrl = `/${item.type_id}`;
    const denominationsUrl = `/${item.denominations}`;
    const billCountUrl = `/${item.converted_bill_count}`;
    const currencyUrl = `/${item.currency}`;
    const createdByUrl = `/${item.created_by}`;
    const url =
      baseUrl +
      changeOrderIdUrl +
      typeIdUrl +
      denominationsUrl +
      billCountUrl +
      currencyUrl +
      createdByUrl;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }
}
