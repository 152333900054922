import apiPrefix from 'middleware/helpers/apiPrefix';

export default class TillTransferDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }

  async fetchData(tillId) {
    if (this.data.length > 0) return this.data;
    console.log(
      'TillCountHeaderDataSource: fetching adjustments for till...',
      tillId
    );

    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };

    const baseUrl = `/retail-cash/adjustment/${tillId}`;
    this.url = apiPrefix + baseUrl;
    const resp = await fetch(this.url, opts);

    if (!resp.ok) return null;

    const data = await resp.json();
    this.data = data;
    console.log('TillCountHeaderDataSource - fetchData response:', data);

    return data;
  }

  async insertSafe(count) {
    console.log('TillCountHeaderDataSource: inserting safe count...', count);
    // round here if there is impresion in floating points
    const rounded_deposit = Math.round(count.amountToDeposit);
    const domain = process.env.OHM_DOMAIN;
    const baseurl = `https://${domain}/api/facility`;
    // need to add a till vs safe id
    const facilityIdUrl = `/${count.facilityId}/retail-cash/till`;
    const tillTypeUrl = `/${count.tillType}`;
    const actionIdUrl = `/${count.actionId}`;
    const actionTypeIdUrl = `/${count.actionTypeId}`;
    const newSafeBalanceURL = `/${count.newBalanceAmount}`;
    const createdByIdUrl = `/${count.createdById}`;
    const createdByNameUrl = `/${count.createdByName}`;
    const depositAmountURL = `/${rounded_deposit}`;
    const transactionIdURL = `/${count.transactionId}/transfer`;

    const url =
      baseurl +
      facilityIdUrl +
      tillTypeUrl +
      actionIdUrl +
      actionTypeIdUrl +
      newSafeBalanceURL +
      createdByIdUrl +
      createdByNameUrl +
      depositAmountURL +
      transactionIdURL;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });

    const data = await resp.json();
    this.data = data;
    return data;
  }

  async insertTill(count) {
    console.log('TillCountHeaderDataSource: inserting till count...', count);
    // round here if there is impresion in floating points
    const rounded_deposit = Math.round(count.amountToDeposit);
    const domain = process.env.OHM_DOMAIN;
    const baseurl = `https://${domain}/api/facility`;
    // need to add a till vs safe id
    const facilityIdUrl = `/${count.facilityId}/retail-cash/till`;
    const tillTypeUrl = `/${count.tillType}`;
    const actionIdUrl = `/${count.actionId}`;
    const actionTypeIdUrl = `/${count.actionTypeId}`;
    const newTillBalanceURL = `/${count.newBalanceAmount}`;
    const createdByIdUrl = `/${count.createdById}`;
    const createdByNameUrl = `/${count.createdByName}`;
    const depositAmountURL = `/${rounded_deposit}`;
    const transactionIdURL = `/${count.transactionId}/transfer`;

    const url =
      baseurl +
      facilityIdUrl +
      tillTypeUrl +
      actionIdUrl +
      actionTypeIdUrl +
      newTillBalanceURL +
      createdByIdUrl +
      createdByNameUrl +
      depositAmountURL +
      transactionIdURL;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });

    const data = await resp.json();
    this.data = data;
    return data;
  }

  async insertItem(item) {
    console.log('TillCountItemDataSource: inserting till count item...', item);
    const domain = process.env.OHM_DOMAIN;
    const baseUrl = `https://${domain}/retail-cash/till-denomination-log`;
    const changeOrderIdUrl = `/${item.till_count_id}`;
    const typeIdUrl = `/${item.type_id}`;
    const denominationsUrl = `/${item.denominations}`;
    const billCountUrl = `/${item.converted_bill_count}`;
    const currencyUrl = `/${item.currency}`;
    const createdByUrl = `/${item.created_by}`;
    const url =
      baseUrl +
      changeOrderIdUrl +
      typeIdUrl +
      denominationsUrl +
      billCountUrl +
      currencyUrl +
      createdByUrl;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });

    const data = await resp.json();
    this.data = data;
    return data;
  }
}
