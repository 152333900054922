import apiPrefix from 'middleware/helpers/apiPrefix';

// for End of Day checks, represented in UTC hours
const EOD_UTC_HOURS = 9;

const isInCurrentDay = (timestamp) => {
  // checks if a given timestamp is within the day
  if (!timestamp) {
    return false;
  }
  const timeToCheck = new Date(timestamp);
  const currentDate = new Date();
  const currentHours = currentDate.getUTCHours();
  if (currentHours < EOD_UTC_HOURS) {
    currentDate.setDate(currentDate.getUTCDate() - 1);
  }
  currentDate.setUTCHours(EOD_UTC_HOURS);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate < timeToCheck;
};

export default class TillTransactionLogDataSource {
  constructor(token, data = {}) {
    this.token = token;
    this.data = data;
    this.url = null;
  }

  async checkCloseCount() {
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const { tillId } = this.data;

    const limit = 1;
    const currentTimestamp = new Date().toISOString();
    const logUrl = `/retail-cash/limit/${limit}/till-transaction-log/${tillId}`;
    const timeStampUrl = `/before/${currentTimestamp}`;
    this.url = apiPrefix + logUrl + timeStampUrl;

    const res = await fetch(this.url, opts);
    if (!res.ok) {
      return null;
    }
    const data = await res.json();
    const filteredData = data.filter((x) => isInCurrentDay(x.created));
    // false if there are no logs for the day
    if (filteredData.length === 0) {
      return false;
    }
    // check if action_type is close count or system auto adjustment
    const current_log = filteredData[0];
    return [3, 8].includes(current_log.action_type_id);
  }
}
