import React from 'react';

import { HeadingPrimaryXS } from '@warbyparker/design-type';
import { Paper, TextField } from '@material-ui/core';

function TillAdjustNote(props) {
  const { form, classes, title } = props;

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>{title}</HeadingPrimaryXS>
      <TextField
        id="adjustment_amount_notes"
        // Have this change by entity
        label="Extra Notes"
        className={classes.textField}
        onChange={form.handleSelectChange('notes')}
        margin="normal"
        InputProps={{
          className: classes.input,
        }}
      />
    </Paper>
  );
}

export default TillAdjustNote;
