import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { BodyMD, HeadingPrimaryXS } from '@warbyparker/design-type';

function TotalTable(props) {
  const { form, classes, title, child_name, till_count } = props;

  function getTotal() {
    if (till_count) return form.state.totalAmount.toFixed(2);
    const total = form.getTotal(form.state.requestedItems);
    return total ? total.toFixed(2) : 0;
  }

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>{title}</HeadingPrimaryXS>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <BodyMD children={child_name} />
            </TableCell>
            <TableCell>
              <BodyMD children="Currency" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <BodyMD children={getTotal()} />
            </TableCell>
            <TableCell>
              <BodyMD children={form.state.currency} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

export default TotalTable;
