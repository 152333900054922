/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import RetailStoresDataSource from 'middleware/retail-cash/RetailStoresDataSource';
import ListChangeOrdersDataSource from 'middleware/retail-cash/change-order/ListChangeOrdersDataSource';
import AuthModal from 'containers/layout/components/AuthModal';
import * as constants from 'static/constants';

import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link as WarbyLink } from '@warbyparker/design-link';
import { BodySM, HeadingPrimaryXS } from '@warbyparker/design-type';
import _ from 'lodash';

const styles = (theme) => ({
  root: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit,
    overflowX: 'auto',
    alignItems: 'center',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 250,
  },
  tableTextField: {
    width: 125,
  },
  menu: {
    width: 100,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
  container: {
    padding: theme.spacing.unit,
  },
  submitButton: {
    alignItems: 'right',
    backgroundColor: '#009ADE',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

const columnData = [
  { id: 'bank_confirmation_number', numeric: false, label: 'Confirmation' },
  { id: 'bank_name', numeric: false, label: 'Bank' },
  { id: 'facility_name', numeric: false, label: 'Store' },
  { id: 'status_name', numeric: false, label: 'Status' },
  { id: 'expected_delivery_date', numeric: false, label: 'Expected Delivery' },
  { id: 'delivery_date', numeric: false, label: 'Delivery' },
  { id: 'create_username', numeric: false, label: 'Created By' },
];

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      order: 'desc',
      orderBy: 'expected_delivery_date',
      changeOrders: [],
      page: 0,
      rowsPerPage: 10,
      query: '',
      columnToQuery: 'bank_confirmation_number',
      dateFrom: new Date(moment().subtract(1, 'months').format('MM-DD-YYYY'))
        .toISOString()
        .slice(0, 10),
      dateTo: new Date(moment().add(7, 'days').format('MM-DD-YYYY'))
        .toISOString()
        .slice(0, 10),
      permissions: props.permissions,
      adminPermission: false,
      retailStores: props.retailStores,
      allRetailStores: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    const adminPermission = this.props.permissions.filter(
      (x) => x === 'finance_account.view'
    );

    if (adminPermission.length > 0) this.state.adminPermission = true;
  }

  async componentDidMount() {
    if (_.isEmpty(this.state.allRetailStores) && this.state.adminPermission) {
      await this.fetchAllRetailStores();
    }

    if (_.isEmpty(this.state.serviceRequests)) {
      await this.fetchChangeOrders();
    }
  }

  async fetchChangeOrders() {
    let { retailStores } = this.state;

    if (this.state.adminPermission) retailStores = this.state.allRetailStores;

    const ChangeOrders = new ListChangeOrdersDataSource(this.state.token);

    if (this.state.adminPermission === true) {
      const resp = await ChangeOrders.fetchData(
        this.state.dateFrom,
        this.state.dateTo
      );
      console.log('List change order response:', resp);

      this.setState({ changeOrders: resp });
    } else {
      const resp = await ChangeOrders.fetchDataByFacilities(
        retailStores.map((x) => x.id),
        this.state.dateFrom,
        this.state.dateTo
      );
      console.log('List change order response:', resp);

      this.setState({ changeOrders: resp });
    }
  }

  async fetchAllRetailStores() {
    const RetailStores = new RetailStoresDataSource(this.props.token);
    const resp = await RetailStores.fetchData();
    this.setState({ allRetailStores: resp });
  }

  createSortHandler = (property) => () => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSelectColumn = () => (event) => {
    this.setState({ columnToQuery: event.target.value });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.submitForm();
  }

  async submitForm() {
    this.fetchChangeOrders();
  }

  render() {
    const { classes } = this.props;
    const { changeOrders, order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, changeOrders.length - page * rowsPerPage);
    const lowerCaseQuery = this.state.query.toLowerCase();

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <Paper className={classes.root}>
          <HeadingPrimaryXS>List of Change Orders</HeadingPrimaryXS>
          <form
            autoComplete="off"
            classes={classes.container}
            onSubmit={this.handleSubmit}
          >
            <TextField
              id="query-select"
              select
              label="Select a column"
              className={classes.tableTextField}
              value={this.state.columnToQuery}
              onChange={this.handleSelectColumn()}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              <MenuItem
                key="bank_confirmation_number"
                value="bank_confirmation_number"
              >
                Confirmation
              </MenuItem>
              <MenuItem key="bank_name" value="bank_name">
                Bank Name
              </MenuItem>
              <MenuItem key="facility_name" value="facility_name">
                Store
              </MenuItem>
              <MenuItem key="create_username" value="create_username">
                Created By
              </MenuItem>
              <MenuItem key="status_name" value="status_name">
                Status
              </MenuItem>
            </TextField>

            <TextField
              id="query-input"
              label="Search"
              className={classes.textField}
              value={this.state.query}
              margin="normal"
              InputProps={{
                className: classes.input,
              }}
              onChange={(e) => this.setState({ query: e.target.value })}
            />
            <TextField
              id="date_from"
              label="Expected Date From"
              type="date"
              value={this.state.dateFrom}
              onChange={(e) => this.setState({ dateFrom: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                className: classes.input,
              }}
              required
            />
            <TextField
              id="date_to"
              label="Expected Date To"
              type="date"
              value={this.state.dateTo}
              onChange={(e) => this.setState({ dateTo: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                className: classes.input,
              }}
              required
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.submitButton}
              type="submit"
              children="Submit"
            >
              Search
            </Button>
          </form>
        </Paper>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {columnData.map(
                    (column) => (
                      <TableCell
                        key={column.id}
                        numeric={column.numeric}
                        padding={column.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === column.id ? order : false}
                      >
                        <Tooltip
                          title="Sort"
                          placement={
                            column.numeric ? 'bottom-end' : 'bottom-start'
                          }
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={this.createSortHandler(column.id)}
                          >
                            <BodySM children={column.label} />
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    ),
                    this
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {changeOrders
                  .filter((x) =>
                    x[this.state.columnToQuery]
                      .toLowerCase()
                      .includes(lowerCaseQuery)
                  )
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => (
                    <TableRow key={n.id}>
                      <TableCell>
                        <BodySM>
                          <WarbyLink href={`/edit-change-order/${n.id}`}>
                            {n.bank_confirmation_number}
                          </WarbyLink>
                        </BodySM>
                      </TableCell>
                      <TableCell>
                        <BodySM children={constants.bank_name[n.bank_name]} />
                      </TableCell>
                      <TableCell>
                        <BodySM children={n.facility_name} />
                      </TableCell>
                      <TableCell>
                        <BodySM children={n.status_name} />
                      </TableCell>
                      <TableCell>
                        <BodySM
                          children={new Date(n.expected_delivery_date)
                            .toISOString()
                            .slice(0, 10)}
                        />
                      </TableCell>
                      <TableCell>
                        <BodySM
                          children={
                            n.delivery_date
                              ? new Date(n.delivery_date)
                                  .toISOString()
                                  .slice(0, 10)
                              : ''
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <BodySM children={n.create_username} />
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 40 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={
              changeOrders.filter((x) =>
                x[this.state.columnToQuery]
                  .toLowerCase()
                  .includes(lowerCaseQuery)
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    );
  }
}

List.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(List);
