import React from 'react';

import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';
import { withStyles } from '@material-ui/core/styles';
import { Button, Modal } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

class DiscrepancyModal extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    this.state = { form };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleConfirm() {
    const data = {
      stationId: this.state.form.state.stationId,
      tillId: this.state.form.state.tillId,
      adjustments: this.state.form.state.outstandingAdjustments,
      user: this.state.form.state.user.id,
      adjId: null,
      statusId: 1,
    };

    await this.state.form.setState({
      discrepancyConfirmed: true,
      discrepancy: false,
    });
    await this.state.form.submitAdjustmentsForm(data);
  }

  async handleClose() {
    await this.state.form.setState({
      discrepancy: false,
      submitButtonDisabled: false,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.form.state.discrepancy}
        onClose={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>There is a discrepancy</HeadingPrimarySM>
          <BodyLG>
            There is a discrepancy between the amount counted and the total
            adjustment amount, would you like to proceed?
          </BodyLG>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.handleConfirm}
            children="Confirm"
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.handleClose}
            children="Cancel"
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(DiscrepancyModal);
