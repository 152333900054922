import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';
import { Button, Modal, TextField } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    this.state = { form };
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async handleConfirm() {
    console.log('Submitting till deposit form:', this.state.form.state.notes);
    await this.state.form.setState({ confirmDeposit: false });

    const data = {
      stationId: this.state.form.state.stationId,
      tillId: this.state.form.state.tillId,
      depositLogId: this.state.form.state.depositLogId,
      user: this.state.form.state.user.id,
      notes: this.state.form.state.notes,
    };

    await this.state.form.submitDepositNotes(data);
  }

  render() {
    const { classes } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.form.state.confirmDeposit}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>Record Deposit Information</HeadingPrimarySM>
          <BodyLG>Record the deposit number on the bag</BodyLG>
          <TextField
            id="deposit_notes"
            label="Deposit Number"
            className={classes.textField}
            onChange={this.state.form.handleSelectChange('notes')}
            margin="normal"
            InputProps={{
              className: classes.input,
            }}
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.handleConfirm}
            children="Submit"
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ConfirmModal);
