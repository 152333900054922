import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

import LoadingPage from 'containers/loading-page';

// Some very ugly code from the docs:
// https://reacttraining.com/react-router/web/example/auth-workflow
const AuthRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props => (
            user ?
                (<Component {...props} />) :
                (<LoadingPage />))
        }
  />
);
const select = state => ({ user: state.auth.me });
export default connect(select)(AuthRoute);
