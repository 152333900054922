import React from 'react';
import { connect } from 'react-redux';

import { LOGOUT } from 'middleware/auth';

import NavigationLayout from 'containers/layout/components/NavigationLayout';
import LandingPage from 'containers/home-page/components/LandingPage';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

function HomePage({
  token,
  user,
  facility,
  retailStores,
  permissions,
  dispatch,
}) {
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Grid>
          <Paper>
            <NavigationLayout
              token={token}
              facility={facility}
              user={user}
              onLogout={() => dispatch({ type: LOGOUT })}
              contained_view={
                <LandingPage
                  user={user}
                  permissions={permissions}
                  retailStores={retailStores}
                />
              }
            />
          </Paper>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
}

const select = (state) => ({
  retailStores: state.auth.me.retail_stores,
  facility: state.auth.me.facility,
  features: state.auth.me.features,
  user: state.auth.me.user,
  token: state.auth.token,
  permissions: state.auth.me.permissions,
});

export default connect(select)(HomePage);
