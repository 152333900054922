import React from 'react';
import Select from 'react-select';

import { loomis_store } from 'static/constants';

import { Paper, TextField, Typography } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function TillDepositHeaderData(props) {
  const { classes, form } = props;
  const { retailStore } = form.state;
  const isNewDeposit = retailStore ? loomis_store.includes(retailStore.value) : null;

  return (
    <Paper className={classes.paperflex}>
      <HeadingPrimaryXS>Till Deposit</HeadingPrimaryXS>
      <Select
        className={classes.textField}
        value={form.state.retailStore}
        options={form.state.retailStoresSelect}
        components={SingleValue}
        onChange={form.handleRetailStoreChange('retailStore')}
        placeholder="Select a Retail Store"
        isDisabled
      />
      <TextField
        id="currentAmount"
        label="Current Amount"
        defaultValue={form.state.countAmount / 100}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="amountToDeposit"
        label="Amount To Deposit"
        defaultValue={form.state.amountToDeposit / 100}
        value={form.state.amountToDeposit / 100}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      {isNewDeposit
        ? (
          <TextField
            id="depositType"
            label="Deposit Type"
            value="Loomis Deposit"
            className={classes.textField}
            margin="normal"
            disabled
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
          />
        )
        : null
      }
    </Paper>
  );
}
export default TillDepositHeaderData;
