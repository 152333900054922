import React from 'react';
import { connect } from 'react-redux';

import { LOGOUT } from 'middleware/auth';
import Form from 'containers/till-safe-transfer/components/SafeToTillForm';

import NavigationLayout from 'containers/layout/components/NavigationLayout';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

class SafeToTillTransfer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid>
            <Paper>
              <NavigationLayout
                token={this.props.token}
                user={this.props.user}
                onLogout={() => this.props.dispatch({ type: LOGOUT })}
                contained_view={
                  <Form
                    token={this.props.token}
                    user={this.props.user}
                    retailStores={this.props.retailStores}
                    facility={this.props.facility}
                    permissions={this.props.permissions}
                  />
                }
              />
            </Paper>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const select = (state) => ({
  retailStores: state.auth.me.retail_stores,
  facility: state.auth.me.facility,
  features: state.auth.me.features,
  user: state.auth.me.user,
  token: state.auth.token,
  permissions: state.auth.me.permissions,
});

export default connect(select)(SafeToTillTransfer);
