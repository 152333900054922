import React from 'react';

import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';
import { withStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

class AuthModal extends React.Component {
  constructor(props) {
    super(props);
    const { permissions, retailStores } = props;
    this.state = {
      open: true,
    };

    const adminPermission = permissions.filter(
      (x) => x === 'finance_account.view'
    );

    if (adminPermission.length > 0) this.state.open = false;
    else if (retailStores.length > 0) this.state.open = false;
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>cash 💵 matters, but....</HeadingPrimarySM>
          <BodyLG>
            apparently your user is not assigned to any retail store
          </BodyLG>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AuthModal);
