import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

class SuccessModal extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    this.state = { form };
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async handleConfirm() {
    await this.state.form.handleFormSuccess();
  }

  render() {
    const { classes } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.form.state.formSuccess}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>Success</HeadingPrimarySM>
          <BodyLG>
            The count was successfully submitted, please hit the Confirm button
          </BodyLG>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.handleConfirm}
            children="Confirm"
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(SuccessModal);
