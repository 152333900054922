/*
Why is this needed?
> is `url.format` broken or am I?
> https://github.com/nodejs/help/issues/1176
Would like to:
- extract this as a standalone lib
- support all spaces as options:
  https://nodejs.org/api/url.html#url_url_strings_and_url_objects
Blarg, chrome's URL object is fullabugs:
https://bugs.chromium.org/p/chromium/issues/detail?id=819342
*/

export default (options) => {
  const defaults = {
    hostname: (window.location && window.location.hostname) || 'localhost',
  };

  const { host, hostname, pathname, query } = { ...defaults, ...options };
  const dummyURL = 'https://.';
  const url = new window.URL(dummyURL);
  url.hostname = hostname;
  url.host = host || url.host;
  url.pathname = pathname || url.pathname;

  if (query) {
    const params = new window.URLSearchParams();
    Object.keys(query).forEach((key) => {
      params.append(key, query[key]);
    });
    url.search = String(params);
  }

  return url.href;
};
