import React from 'react';

import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';
import { Button, Modal, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

class DiscrepancyCountModal extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    this.state = {
      form,
      discrepancyAmount:
        (form.state.countAmount -
          (form.state.currentAmount + form.state.adjustmentTotal)) /
        100,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async handleConfirm() {
    const data = {
      stationId: this.state.form.state.stationId,
      tillId: this.state.form.state.tillId,
      countLogId: this.state.form.state.tillTransactionLogID,
      user: this.state.form.state.user.id,
      countNotes: this.state.form.state.countNotes,
    };

    await this.state.form.setState({
      discrepancyCount: false,
      processing: true,
      submitButtonDisabled: true,
    });

    await this.state.form.submitCountNotes(data);
  }

  async handleClose() {
    await this.state.form.setState({ requestedItems: [] });
    this.state.form.createEmptyItems();
    await this.state.form.setState({
      discrepancyCount: false,
      submitButtonDisabled: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.form.state.discrepancyCount}
        onClose={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>
            There is a discrepancy of ${this.state.discrepancyAmount}
          </HeadingPrimarySM>
          <BodyLG>
            If this is incorrect, please select Cancel, and count again. If this
            is correct, please add notes below and hit Confirm.
          </BodyLG>
          <TextField
            id="count_notes"
            label="Count Notes"
            className={classes.textField}
            fullWidth
            onChange={this.state.form.handleTextFieldChange('countNotes')}
            margin="normal"
            InputProps={{
              className: classes.input,
            }}
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={this.handleConfirm}
            className={classes.button}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={this.handleClose}
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(DiscrepancyCountModal);
