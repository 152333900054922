import React from 'react';
import { connect } from 'react-redux';

import { LOGOUT } from 'middleware/auth';

import NavigationLayout from 'containers/layout/components/NavigationLayout';
import List from 'containers/list-change-orders/components/List';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

function ListChangeOrders({
  token,
  user,
  facility,
  retailStores,
  permissions,
  dispatch,
}) {
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Grid>
          <Paper>
            <NavigationLayout
              token={token}
              user={user}
              onLogout={() => dispatch({ type: LOGOUT })}
              contained_view={
                <List
                  token={token}
                  facility={facility}
                  retailStores={retailStores}
                  permissions={permissions}
                />
              }
            />
          </Paper>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
}

const select = (state) => ({
  facility: state.auth.me.facility,
  features: state.auth.me.features,
  user: state.auth.me.user,
  token: state.auth.token,
  permissions: state.auth.me.permissions,
  retailStores: state.auth.me.retail_stores,
});
export default connect(select)(ListChangeOrders);
