import apiPrefix from 'middleware/helpers/apiPrefix';

export default class TillCountHeaderDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }

  async fetchAdj(location) {
    if (this.data.length > 0) {
      return this.data;
    }
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const baseUrl = '/retail-cash/adjustment';
    const stationIdUrl = `/${location.stationId}`;
    const tillIdUrl = `/${location.tillId}`;
    const statusUrl = `/${location.statusId}`;
    this.url = apiPrefix + baseUrl + stationIdUrl + tillIdUrl + statusUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.text();
    const jsonData = JSON.parse(
      data.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')
    );
    this.data = jsonData;
    return jsonData;
  }

  async insert(count) {
    const domain = process.env.OHM_DOMAIN;
    const baseurl = `https://${domain}/api/facility`;
    const facilityIdUrl = `/${count.facilityId}/retail-cash/till`;
    const tillTypeUrl = `/${count.tillType}`;
    const actionIdUrl = `/${count.actionId}`;
    const actionTypeIdUrl = `/${count.actionTypeId}`;
    const totalAmountURL = `/${count.totalAmount}`;
    const createdByIdUrl = `/${count.createdById}`;
    const createdByNameUrl = `/${count.createdByName}`;

    const url =
      baseurl +
      facilityIdUrl +
      tillTypeUrl +
      actionIdUrl +
      actionTypeIdUrl +
      totalAmountURL +
      createdByIdUrl +
      createdByNameUrl;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }

  async updateCount(updateInfo) {
    const domain = process.env.OHM_DOMAIN;
    const baseurl = `https://${domain}/retail-cash/till-transaction-log`;
    const transactionLogIdUrl = `/${updateInfo.countLogId}/update`;
    const notesUrl = `/${updateInfo.countNotes}`;

    const url = baseurl + transactionLogIdUrl + notesUrl;

    const resp = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }

  async updateAdj(adjustment) {
    // Need to fix this
    // console.log('TillCountHeaderDataSource: updating adjustment...');
    const domain = process.env.OHM_DOMAIN;
    const baseUrl = `https://${domain}/retail-cash/adjustment`;
    const stationIdUrl = `/${adjustment.stationId}`;
    const tillIdUrl = `/${adjustment.tillId}`;
    const statusUrl = `/${adjustment.statusId}/update`;
    const adjIdUrl = `/${adjustment.adjId}`;

    const url = baseUrl + stationIdUrl + tillIdUrl + statusUrl + adjIdUrl;
    // console.log(url);
    const resp = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }
}
