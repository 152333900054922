import apiPrefix from 'middleware/helpers/apiPrefix';

export default class RetailStoresDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchData() {
    if (this.data.length > 0) {
      return this.data;
    }
    console.log('RetailStoresDataSource: fetching Retail Stores...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const changeOrderUrl = '/retail-cash/retail-stores';
    this.url = apiPrefix + changeOrderUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.json();
    this.data = data;
    return data;
  }
}
