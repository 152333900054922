import React from 'react';

import { BodyMD, HeadingPrimaryXS } from '@warbyparker/design-type';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

function ChangeOrderTotalTable(props) {
  const { form, classes, title } = props;

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>{title}</HeadingPrimaryXS>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <BodyMD children="Requested" />
            </TableCell>
            <TableCell>
              <BodyMD children="Received" />
            </TableCell>
            <TableCell>
              <BodyMD children="Difference" />
            </TableCell>
            <TableCell>
              <BodyMD children="Currency" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <BodyMD
                children={
                  form.getTotal(form.state.requestedItems)
                    ? form.getTotal(form.state.requestedItems).toFixed(2)
                    : 0
                }
              />
            </TableCell>
            <TableCell>
              <BodyMD
                children={
                  form.getTotal(form.state.receivedItems)
                    ? form.getTotal(form.state.receivedItems).toFixed(2)
                    : 0
                }
              />
            </TableCell>
            <TableCell>
              <BodyMD
                children={
                  Math.abs(
                    form.getTotal(form.state.requestedItems) -
                      form.getTotal(form.state.receivedItems)
                  ).toFixed(2)
                    ? Math.abs(
                        form.getTotal(form.state.requestedItems) -
                          form.getTotal(form.state.receivedItems)
                      ).toFixed(2)
                    : 0
                }
              />
            </TableCell>
            <TableCell>
              <BodyMD children={form.state.requestedItems[0].currency} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ChangeOrderTotalTable;
