/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  AppBar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  AccountCircle,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import styled from 'react-emotion';
import { Link as WarbyLink } from '@warbyparker/design-link';
import { BodyLG, HeadingPrimaryXS } from '@warbyparker/design-type';
import BillFoldIcon from 'containers/icons/billfold_icon.png';
import logo from '../../../static/assets/images/logo.png';

const drawerWidth = 240;

const Item = styled('li')`
  align-items: center;
  list-style: none;
  white-space: nowrap;
  padding: 10;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
  &:focus {
    outline: none;
  }
`;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  flex: {
    flexGrow: 1,
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#00A2E1',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
  },
  'appBarShift-right': {
    marginRight: drawerWidth,
  },
  hamburgerButton: {
    marginLeft: 12,
    marginRight: 5,
  },
  menuRightButton: {
    marginLeft: 'auto',
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
});

class NavigationLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      user: props.user,
      onLogout: props.onLogout,
      contained_view: props.contained_view,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { open, anchorEl } = this.state;
    const loginMenuOpen = Boolean(anchorEl);
    const { classes, theme } = this.props;

    const ActionItemsList = (
      <div className={classes.listItems}>
        <Item>
          <WarbyLink href="/till-count" alt>
            <BodyLG>Till Count</BodyLG>
          </WarbyLink>
        </Item>
        <Item>
          <WarbyLink href="/till-adjustment" alt>
            <BodyLG>Till Adjustment Notes</BodyLG>
          </WarbyLink>
        </Item>
        <Item>
          <HeadingPrimaryXS>Transfers</HeadingPrimaryXS>
          <WarbyLink href="/safe-to-till-transfer" alt>
            <BodyLG>Safe To Till</BodyLG>
          </WarbyLink>
          <WarbyLink href="/till-to-safe-transfer" alt>
            <BodyLG>Till To Safe</BodyLG>
          </WarbyLink>
        </Item>
        <Divider />
        <Item>
          <WarbyLink href="/create-change-order" alt>
            <BodyLG>Create Change Order</BodyLG>
          </WarbyLink>
        </Item>
        <Item>
          <WarbyLink href="/list-change-orders" alt>
            <BodyLG>List Change Orders</BodyLG>
          </WarbyLink>
        </Item>
      </div>
    );

    const drawer = (
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img
            src={logo}
            alt=""
            width="50"
            height="50"
            className={classes.img}
          />
          <IconButton onClick={this.handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{ActionItemsList}</List>
        <Divider />
      </Drawer>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes['appBarShift-left']]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.hamburgerButton,
                  open && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
              <WarbyLink href="/" alt>
                <HeadingPrimaryXS invert>
                  <img src={BillFoldIcon} alt="" width="25" height="16" />{' '}
                  Billfold
                </HeadingPrimaryXS>
              </WarbyLink>
              <div className={classNames(classes.menuRightButton)}>
                <HeadingPrimaryXS invert>
                  {this.state.user.name}
                </HeadingPrimaryXS>
                <Typography variant="title" color="inherit" noWrap>
                  <IconButton
                    aria-owns={loginMenuOpen ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={loginMenuOpen}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.state.onLogout}>Logout</MenuItem>
                  </Menu>
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          {drawer}
          <main
            className={classNames(classes.content, classes['content-left'], {
              [classes.contentShift]: open,
              [classes['contentShift-left']]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Grid item xs={12}>
              {this.state.contained_view}
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

NavigationLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NavigationLayout);
