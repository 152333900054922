import apiPrefix from 'middleware/helpers/apiPrefix';

export default class TillAdminCurrentAmountDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchData(facilityId, tillType) {
    if (this.data.length > 0) return this.data;

    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
      credentials: 'include',
    };

    const tillIdUrl = `/api/facility/${facilityId}`;
    const endUrl = `/retail-cash/till/${tillType}`;
    this.url = apiPrefix + tillIdUrl + endUrl;
    const resp = await fetch(this.url, opts);

    if (!resp.ok) return null;

    const data = resp.json();
    this.data = data;
    return data;
  }
}
