import React from 'react';

import { Button, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

class DiscrepancyModal extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    this.state = { form };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleConfirm() {
    const data = {
      receivedItems: this.state.form.state.receivedItems,
      statusKey: this.state.form.state.statusKey,
    };

    await this.state.form.setState({
      discrepancyConfirmed: true,
      discrepancy: false,
      processing: true,
    });

    for (let i = 0; i < data.receivedItems.length; i++) {
      if (data.receivedItems[i] === '') data.receivedItems[i] = 0;
    }

    await this.state.form.submitForm(data);
  }

  async handleClose() {
    await this.state.form.setState({
      discrepancy: false,
      submitButtonDisabled: false,
    });
  }

  render() {
    const { classes } = this.props;
    let discrepancyText =
      'There is a discrepancy between the amount received and the amount requested, would you like to proceed?';

    if (this.state.form.state.dateDiscrepancy) {
      discrepancyText =
        "The delivery date can't be before the expected delivery date, would you like to proceed?";
    }

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.form.state.discrepancy}
        onClose={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>There is a discrepancy</HeadingPrimarySM>
          <BodyLG>{discrepancyText}</BodyLG>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.handleConfirm}
            children="Confirm"
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.handleClose}
            children="Cancel"
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(DiscrepancyModal);
