/* eslint-disable react/no-unused-state */
/* global _ */
import React from 'react';
import { Redirect } from 'react-router-dom';

import RetailStoresDataSource from 'middleware/retail-cash/RetailStoresDataSource';
import TillCountItemDataSource from 'middleware/retail-cash/till/TillCountItemDataSource';
import TillDepositDataSource from 'middleware/retail-cash/till/TillDepositDataSource';
import TillTransactionLogDataSource from 'middleware/retail-cash/till/TillTransactionLogDataSource';
import * as constants from 'static/constants';

import TillDepositHeaderData from 'containers/till-deposit/components/TillDepositHeaderData';
import EditTable from 'containers/denominations-display/components/EditTable';
import TotalTable from 'containers/denominations-display/components/TotalTable';

import ConfirmModal from 'containers/till-deposit/components/ConfirmModal';
import SnackBarNotification from 'containers/layout/components/SnackBarNotification';
import AuthModal from 'containers/layout/components/AuthModal';
import ErrorOhmModal from 'containers/shared/components/ErrorOhmModal';
import LoadingOhmModal from 'containers/shared/components/LoadingOhmModal';

import { formStyles } from 'containers/layout/components/FormStyles';
import { HeadingPrimaryXS } from '@warbyparker/design-type';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function createData(
  row_id,
  id,
  till_count_id,
  type_id,
  denominations,
  bill_count,
  currency,
  created_by_id
) {
  return {
    row_id: row_id + 1,
    id,
    till_count_id,
    type_id,
    denominations,
    bill_count,
    currency,
    created_by_id,
  };
}

const defaultDate = () => new Date().toISOString().slice(0, 10);

const getTotal = (data) => {
  let total = 0;
  for (let i = 0; i < data.length; i += 1) {
    total += data[i].bill_count * data[i].denominations;
  }
  return total;
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminPermission: false,
      token: props.token,
      user: props.user,
      entityId: props.entityId,
      entityName: props.entityName,
      bankKey: 'bank_of_america',
      expectedDeliveryDate: defaultDate(),
      requestedItems: [],
      currentDenominations: [],
      currency: props.facility.currency,
      coinConversion: constants.usd_coin_conversion,
      denomination_options: constants.usd_denomination_options,
      openNotification: false,
      notificationMessage: null,
      notificationVariant: null,
      formSuccess: false,
      redirect: false,
      submitButtonDisabled: false,
      confirmDeposit: false,
      allRetailStores: props.allRetailStores,
      retailStore: props.retailStore,
      retailStoresSelect: props.retailStoresSelect,
      retailStores: props.retailStores,
      permissions: props.permissions,
      tillId: props.tillId,
      countAmount: props.countAmount,
      amountToDeposit: props.countAmount - 30000,
      processing: false,
      processingError: false,
      hasCloseCount: null,
      notes: '',
      stationId: props.stationId,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTotal = getTotal;

    const adminPermission = this.props.permissions.filter(
      (x) => x === 'finance_account.view'
    );

    if (adminPermission.length > 0) this.state.adminPermission = true;
  }

  async componentDidMount() {
    if (_.isEmpty(this.state.allRetailStores) && this.state.adminPermission) {
      await this.fetchAllRetailStores();
    }

    if (this.state.entityName) {
      this.selectBank(this.state.entityName);
      this.selectCurrency(this.state.entityName);
      this.getDepositAmounts();
      this.createEmptyItems();
    }

    if (this.state.tillId && !this.props.hasCloseCount) {
      await this.checkCloseCount(this.state.tillId);
    }
  }

  onBillCountChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;
    for (let i = 0; i < requestedItems.length; i += 1) {
      const item_row_id = requestedItems[i].row_id;
      requestedItems[i].currency = this.state.currency;
      if (row_id === item_row_id) {
        requestedItems[i][name] = parseInt(event.target.value, 10);
        if (event.target.value < 0) requestedItems[i][name] = 0;
        if (Number.isNaN(parseInt(event.target.value, 10))) {
          requestedItems[i][name] = '';
        }
      }
    }
    this.setState({ requestedItems });
  };

  selectRetailStore(selectedFacility, retailStores) {
    let currentStores = retailStores;
    if (this.state.adminPermission) currentStores = this.state.allRetailStores;

    const retailStoresSelect = currentStores.map((retailStore) => ({
      value: retailStore.id,
      label: retailStore.name,
      locale: retailStore.locale,
    }));

    this.setState({ retailStoresSelect });

    const defaultRetailStore = retailStoresSelect.filter(
      (x) => x.value === selectedFacility
    );
    let retailStore = retailStoresSelect[0];

    if (defaultRetailStore.length > 0) [retailStore] = defaultRetailStore;

    this.setState({ retailStore });

    const entity = constants.entity_options.filter(
      (x) => x.label === constants.country_codes_entity[retailStore.locale]
    );

    this.setState({ entityName: entity[0].label, entityId: entity[0].value });
    this.selectBank(entity[0].label);
    this.selectCurrency(entity[0].label);
  }

  selectBank(entityName) {
    const bank = constants.bank_options.filter((x) => x.entity === entityName);
    this.setState({ bankKey: bank[0].value });
  }

  selectCurrency(entityName) {
    this.setState({ currency: constants.entity_default_currency[entityName] });

    const { requestedItems } = this.state;
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (constants.entity_default_currency[entityName] === 'CAD') {
      this.setState({
        coinConversion: constants.cad_coin_conversion,
        denomination_options: constants.cad_denomination_options,
      });

      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < requestedItems.length; i += 1) {
      requestedItems[i].denominations = den_options.filter(
        (x) => x.label === denominations[i]
      )[0].value;
      requestedItems[i].currency =
        constants.entity_default_currency[entityName];
    }
    this.setState({ requestedItems });

    const currentDenominations = [];

    for (let i = 0; i < denominations.length; i += 1) {
      const denomination = den_options.filter(
        (x) => x.label === denominations[i]
      );
      currentDenominations.push(denomination[0]);
    }
    this.setState({ currentDenominations });
  }

  async fetchAllRetailStores() {
    const RetailStores = new RetailStoresDataSource(this.props.token);
    const resp = await RetailStores.fetchData();

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to get list of retail stores',
      });
    }
    this.setState({ allRetailStores: resp });
  }

  createEmptyItems() {
    let row_id = 1;
    const data = [];
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (this.state.currency === 'CAD') {
      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < denominations.length; i += 1) {
      const denomination = den_options.filter(
        (x) => x.label === denominations[i]
      );

      data.push(
        createData(
          row_id,
          null,
          null,
          1,
          denomination[0].value,
          '',
          this.state.currency,
          null
        )
      );
      row_id += 1;
    }
    this.setState({ requestedItems: data });
  }

  getDepositAmounts = () => {
    const { amountToDeposit, retailStore } = this.state;
    const isNewDeposit = constants.loomis_store.includes(retailStore.value);
    if (!isNewDeposit) {
      return;
    }
    const newDepositAmount = Math.floor(amountToDeposit / 100) * 100;
    this.setState({ amountToDeposit: newDepositAmount.toString() });
  }

  handleRetailStoreChange = (name) => (value) => {
    this.setState({ [name]: value });
    this.selectRetailStore(value.value, this.state.retailStores);
  };

  handleSelectChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleRequestedChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i += 1) {
      const item_row_id = requestedItems[i].row_id;
      if (row_id === item_row_id) requestedItems[i][name] = event.target.value;
    }
    this.setState({ requestedItems });
  };

  handleCurrencyChange = (name) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i += 1) {
      requestedItems[i][name] = event.target.value;
    }
    this.setState({ requestedItems, currency: event.target.value });
  };

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      createdById: this.state.user.id,
      createdByName: this.state.user.username,
      actionId: constants.action_ids.credit,
      actionTypeId: constants.action_type_ids.deposit,
      tillType: constants.till_types.till,
      tillId: this.state.tillId,
      stationId: this.state.stationId,
      facilityId: this.state.retailStore.value,
      requestedItems: this.state.requestedItems,
      totalAmount: 30000,
      inputAmount: (
        (this.getTotal(this.state.requestedItems)
          ? this.getTotal(this.state.requestedItems)
          : 0) * 100.0
      ).toFixed(),
      amountToDeposit: this.state.amountToDeposit,
      transactionId: this.state.retailStore.value
        .toString()
        .concat('_')
        .concat(this.state.expectedDeliveryDate),
    };
    const isInsert = Number(data.inputAmount) === Number(data.amountToDeposit);

    if (!isInsert) {
      this.setState({
        openNotification: true,
        notificationMessage:
          'The input has to be equal to the Amount to Deposit value on top',
        notificationVariant: 'warning',
      });
    } else {
      this.setState({ submitButtonDisabled: true, processing: true });
      this.submitForm(data);
    }
  }

  async submitForm(data) {
    const depositLogId = await this.insertDeposit(data);
    // Need to have submit once safe is complete
    this.setState({ depositLogId });
    const requestedItems = await this.insertItems(data.requestedItems);

    if (requestedItems.length > 0 && depositLogId) {
      await this.setState({
        processing: false,
        confirmDeposit: true,
      });
    }
  }

  async insertDeposit(depositData) {
    const TillDeposit = new TillDepositDataSource(this.state.token);
    const resp = await TillDeposit.insert_with_je(depositData);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit deposit',
      });
      return null;
    }
    return resp.till_transaction_log_id;
  }

  async updateDeposit(depositData) {
    // Update deposit notes field
    const TillDeposit = new TillDepositDataSource(this.state.token);
    const resp = await TillDeposit.update(depositData);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error trying to add deposit bag number',
      });
      return null;
    }
    return resp[0].id;
  }

  async insertItems(requestedItems) {
    const insertedItems = [];

    for (let i = 0; i < requestedItems.length; i += 1) {
      if (!requestedItems[i].id && requestedItems[i].bill_count > 0) {
        insertedItems.push(this.insertItem(requestedItems, i));
      }
    }
    const results = await Promise.all(insertedItems);

    if (insertedItems.length > 0) this.setState({ requestedItems: results });

    return results;
  }

  async insertItem(requestedItems, index) {
    const currentItem = requestedItems[index];
    const TillCountItem = new TillCountItemDataSource(this.state.token);

    currentItem.till_count_id = this.state.depositLogId;
    currentItem.type_id = 5;
    currentItem.created_by = this.state.user.id;
    currentItem.converted_bill_count = currentItem.bill_count;

    const resp = await TillCountItem.insertItem(currentItem);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit deposit',
      });
      return null;
    }
    return currentItem;
  }

  async submitDepositNotes(data) {
    this.setState({
      submitButtonDisabled: true,
      processing: true,
    });

    const updatedDepositId = await this.updateDeposit(data);
    if (updatedDepositId) {
      await this.setState({
        formSuccess: true,
        processing: false,
        openNotification: true,
        notificationMessage: `Deposit: ${this.state.depositLogId} created`,
        notificationVariant: 'success',
      });
    }
  }

  handleClick = () => {
    this.setState({ openNotification: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openNotification: false });
    if (this.state.formSuccess) this.setState({ redirect: true });
  };

  async checkCloseCount(tillId) {
    const data = { tillId };
    const tillSource = new TillTransactionLogDataSource(this.state.token, data);
    const check = await tillSource.checkCloseCount();
    this.setState({ hasCloseCount: check });
  }

  convertDenominations(denominations) {
    return (
      denominations *
      this.state.coinConversion[(denominations * 100).toString()]
    );
  }

  render() {
    const { classes } = this.props;
    const disable = this.state.formSuccess || this.state.confirmAdjustments;

    if (this.state.entityId === null) return <Redirect to="/" />;

    if (
      _.isEmpty(this.state.retailStoresSelect) ||
      !this.state.bankKey ||
      !this.state.entityId
    ) {
      return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
    }

    // redirect if countAmount is less than default (30000) or missing close count for the day
    if (
      this.state.redirect ||
      this.state.amountToDeposit <= 0 ||
      this.state.hasCloseCount === false
    ) {
      return <Redirect to="/" />;
    }

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <form
          autoComplete="off"
          classes={classes.container}
          onSubmit={this.handleSubmit}
        >
          {this.state.processingError ? <ErrorOhmModal form={this} /> : null}
          {this.state.processing ? <LoadingOhmModal form={this} /> : null}
          {this.state.confirmDeposit ? <ConfirmModal form={this} /> : null}
          <TillDepositHeaderData form={this} classes={classes} />
          <EditTable
            form={this}
            classes={classes}
            title="Count"
            denomination_options={this.state.currentDenominations}
            currency_options={constants.currency_options}
            disable_denomination_select={disable}
            disable_count_field={disable}
            change_order={false}
            convert={false}
            request
          />
          <TotalTable
            form={this}
            classes={classes}
            title="Total"
            child_name="Count"
            till_count={false}
          />
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.submitButton}
              type="submit"
              disabled={this.state.submitButtonDisabled}
            >
              Submit
            </Button>
          </Grid>
        </form>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            open={this.state.openNotification}
            onClose={this.handleClose}
          >
            <SnackBarNotification
              onClose={this.handleClose}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
            />
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

export default withStyles(formStyles)(Form);
