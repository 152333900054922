/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
import React from 'react';

import ChangeOrderHeaderData from 'containers/edit-change-order/components/ChangeOrderHeaderData';
import ChangeOrderItemTable from 'containers/edit-change-order/components/ChangeOrderItemTable';
import EditTable from 'containers/denominations-display/components/EditTable';
import ChangeOrderTotalTable from 'containers/edit-change-order/components/ChangeOrderTotalTable';

import DiscrepancyModal from 'containers/edit-change-order/components/DiscrepancyModal';
import ErrorOhmModal from 'containers/shared/components/ErrorOhmModal';
import LoadingOhmModal from 'containers/shared/components/LoadingOhmModal';
import AuthModal from 'containers/layout/components/AuthModal';
import * as constants from 'static/constants';

import ChangeOrderItemDataSource from 'middleware/retail-cash/change-order/ChangeOrderItemDataSource';
import ChangeOrderHeaderDataSource from 'middleware/retail-cash/change-order/ChangeOrderHeaderDataSource';
import TillAdminCurrentAmountDataSource from 'middleware/retail-cash/till/TillAdminCurrentAmountDataSource';
import ChangeOrderJournalEntry from 'middleware/journal-entry/change-order/ChangeOrderHeliosJournal';

import SnackBarNotification from 'containers/layout/components/SnackBarNotification';
import { formStyles } from 'containers/layout/components/FormStyles';
import { withStyles } from '@material-ui/core/styles';
import { HeadingPrimaryXS } from '@warbyparker/design-type';
import { Button, Grid, Snackbar } from '@material-ui/core';

const styles = formStyles;

const status_name = [];

function createData(
  row_id,
  id,
  change_order_id,
  denominations,
  bill_count,
  currency,
  created_by_id,
  updated_by_id
) {
  row_id += 1;
  return {
    row_id,
    id,
    change_order_id,
    denominations,
    bill_count,
    currency,
    created_by_id,
    updated_by_id,
  };
}

function defaultDeliveryDate() {
  return new Date().toISOString().slice(0, 10);
}

function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);
  return newDate.toISOString().slice(0, 10);
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      user: props.user,
      retailStores: props.retailStores,
      permissions: props.permissions,
      changeOrderId: props.changeOrderId,
      changeOrderHeader: null,
      deliveryDate: defaultDeliveryDate(),
      expectedDeliveryDate: null,
      requestedItems: null,
      statusKey: null,
      currency: null,
      coinConversion: constants.usd_coin_conversion,
      filtered_denominations: constants.usd_denomination_options,
      receivedItems: [],
      openNotification: false,
      notificationMessage: null,
      submitButtonDisabled: false,
      discrepancy: false,
      discrepancyConfirmed: false,
      dateDiscrepancy: false,
      currentAmount: 0,
      processing: false,
      processingError: false,
      processingErrorMessage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.convertUTCDateToLocalDate = convertUTCDateToLocalDate;
  }

  async componentDidMount() {
    if (_.isEmpty(this.state.changeOrderHeader)) {
      await this.fetchChangeOrder();
      await this.fetchChangeOrderItems(this.state.requestedItems, 'requested');
      await this.fetchChangeOrderItems(this.state.receivedItems, 'received');
    }
  }

  async getCurrentBalance(store, tillType) {
    const currentAmount = new TillAdminCurrentAmountDataSource(
      this.state.token
    );
    const resp = await currentAmount.fetchData(store, tillType);

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to get till balance',
      });
    }

    if (resp[0]) this.setState({ currentAmount: resp[0].balance_amount });
  }

  async fetchChangeOrder() {
    const ChangeOrder = new ChangeOrderHeaderDataSource(this.props.token);
    const resp = await ChangeOrder.fetchData(this.state.changeOrderId);

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to get change order',
      });
    }

    if (this.state.expectedDeliveryDate === null) {
      this.setState({
        expectedDeliveryDate: this.convertUTCDateToLocalDate(
          new Date(resp[0].expected_delivery_date)
        ),
      });
    }

    this.setState({ changeOrderHeader: resp[0] });
    this.onStatusChange(resp[0].status_key);

    if (
      resp[0].status_key === 'canceled' ||
      resp[0].status_key === 'received'
    ) {
      this.state.submitButtonDisabled = true;
    }

    this.getCurrentBalance(resp[0].facility_id, 1);
  }

  async fetchChangeOrderItems(data, type) {
    const ChangeOrderItems = new ChangeOrderItemDataSource(this.props.token);
    const resp = await ChangeOrderItems.fetchData(
      this.state.changeOrderId,
      constants.change_order_item_type[type]
    );

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to get change order items',
      });
    }

    let conversion = this.state.coinConversion;

    for (let i = 0; i < resp.length; i++) {
      // Need this here because setState is too slow
      if (resp[i].currency === 'CAD') conversion = constants.cad_coin_conversion;
      resp[i].bill_count /=
        conversion[(resp[i].denominations * 100).toString()];
    }

    if (type === 'requested') {
      this.setState({ requestedItems: resp, currency: resp[0].currency });

      let denominations = constants.usd_denominations;
      let den_options = constants.usd_denomination_options;

      if (resp[0].currency === 'CAD') {
        this.setState({
          coinConversion: constants.cad_coin_conversion,
          filtered_denominations: constants.cad_denomination_options,
        });

        denominations = constants.cad_denominations;
        den_options = constants.cad_denomination_options;
      }

      const filtered_denominations = den_options.filter((item) =>
        denominations.includes(item.label)
      );

      this.setState({ filtered_denominations });
    }

    if (type === 'received') {
      let row_count = 1;
      const receivedItems = [];

      for (let i = 0; i < resp.length; i++) {
        receivedItems.push(
          createData(
            row_count,
            resp[i].id,
            this.state.changeOrderId,
            resp[i].denominations,
            resp[i].bill_count,
            resp[i].currency,
            resp[i].created_by_id,
            resp[i].updated_by_id
          )
        );
        row_count += 1;
      }

      this.setState({ receivedItems });

      if (_.isEmpty(receivedItems) && this.state.statusKey !== 'canceled') {
        this.createEmptyItems();
      }
    }
  }

  createEmptyItems() {
    let row_id = 1;
    const data = [];
    let den_options = constants.usd_denomination_options;

    if (this.state.currency === 'CAD') {
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < 4; i++) {
      const denomination = den_options.filter(
        (x) => x.label === constants.denomination_defaults[i]
      );

      data.push(
        createData(
          row_id,
          null,
          null,
          denomination[0].value,
          '',
          this.state.currency,
          null,
          null
        )
      );
      row_id += 1;
    }

    this.setState({ receivedItems: data });
  }

  onStatusChange(statusKey) {
    if (statusKey === 'requested') {
      status_name.push({ label: 'Requested', value: 'requested' });
      status_name.push({ label: 'Canceled', value: 'canceled' });
    }

    if (statusKey === 'received') {
      status_name.push({ label: 'Received', value: 'received' });
    }

    if (statusKey === 'canceled') {
      status_name.push({ label: 'Canceled', value: 'canceled' });
    }

    this.setState({ statusKey });
  }

  handleSelectStatus = (name) => (event) => {
    if (event.target.value === 'canceled') {
      const receivedItems = [];

      for (let i = 0; i < this.state.receivedItems.length; i++) {
        if (this.state.receivedItems[i].id) {
          receivedItems.push(this.state.receivedItems[i]);
        }
      }

      this.setState({ receivedItems, submitButtonDisabled: false });
    }

    this.setState({ [name]: event.target.value });
  };

  handleSelectChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleAddItem = () => () => {
    const { receivedItems } = this.state;
    const row_id = this.state.receivedItems.length + 1;

    if (row_id > 10) return null;

    // Let's not have more than 5 rows per change order received
    receivedItems.push(
      createData(row_id, null, null, 0.25, '', this.state.currency, null, null)
    );

    this.setState({ receivedItems });
  };

  handleRemoveItem = () => () => {
    const { receivedItems } = this.state;

    if (receivedItems.length === 1) return;

    if (!receivedItems[receivedItems.length - 1].id) {
      receivedItems.pop();
      this.setState({ receivedItems });
    }
  };

  getTotal(data) {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total +=
        data[i].bill_count *
        (data[i].denominations *
          this.state.coinConversion[(data[i].denominations * 100).toString()]);
    }
    return total;
  }

  convertDenominations(denominations) {
    return (
      denominations *
      this.state.coinConversion[(denominations * 100).toString()]
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      receivedItems: this.state.receivedItems,
      statusKey: this.state.statusKey,
      deliveryDate: this.state.deliveryDate,
    };

    let isInsert = false;

    if (this.state.statusKey === 'requested') {
      for (let i = 0; i < data.receivedItems.length; i++) {
        if (data.receivedItems[i] === '') data.receivedItems[i] = 0;
        if (data.receivedItems[i].bill_count > 0) {
          isInsert = true;
          break;
        }
      }
    } else isInsert = true;

    if (!isInsert) {
      this.setState({
        openNotification: true,
        notificationMessage:
          'The Change Order needs to have a requested amount to be created',
        notificationVariant: 'warning',
      });
      return null;
    }

    this.setState({ submitButtonDisabled: true, processing: true });
    this.submitForm(data);
  }

  async submitForm(data) {
    console.log('Submitting edit-change-order form w/ data:', data);

    if (this.state.statusKey === 'requested') {
      if (!this.state.discrepancyConfirmed) {
        if (
          Math.abs(
            this.getTotal(this.state.requestedItems) -
              this.getTotal(this.state.receivedItems)
          ) > 0
        ) {
          await this.setState({
            discrepancy: true,
            processing: false,
            submitButtonDisabled: true,
          });
          return null;
        }
      }

      if (!this.state.dateDiscrepancy) {
        if (data.deliveryDate < this.state.expectedDeliveryDate) {
          await this.setState({
            discrepancy: true,
            processing: false,
            dateDiscrepancy: true,
            submitButtonDisabled: true,
          });
          return null;
        }
      }

      const receivedItems = await this.insertItems(data.receivedItems);

      if (receivedItems.length > 0) {
        await this.updateStatus('received');
        this.updateTill(data);
        await this.setState({
          processing: false,
          openNotification: true,
          notificationMessage: `Change Order: ${this.state.changeOrderId} saved`,
          notificationVariant: 'success',
        });
      }
    }

    if (data.statusKey === 'canceled') {
      await this.updateStatus('canceled');
      await this.setState({
        processing: false,
        openNotification: true,
        notificationMessage: `Change Order: ${this.state.changeOrderId} canceled`,
        notificationVariant: 'warning',
      });
    }

    ChangeOrderJournalEntry.createChangeOrderJournalEntry(
      this.state.changeOrderId,
      this.state.token
    );

    this.setState({ submitButtonDisabled: true });
  }

  async insertItems(receivedItems) {
    const insertedItems = [];
    for (let i = 0; i < receivedItems.length; i++) {
      if (receivedItems[i] === '') receivedItems[i] = 0;

      if (!receivedItems[i].id && receivedItems[i].bill_count > 0) {
        const receivedItem = await this.insertItem(receivedItems, i);
        insertedItems.push(receivedItem);
      }
    }

    if (insertedItems.length > 0) {
      this.setState({ receivedItems: insertedItems });
    }

    return insertedItems;
  }

  async insertItem(receivedItems, index) {
    const ChangeOrderItem = new ChangeOrderItemDataSource(this.state.token);

    receivedItems[index].change_order_id = this.state.changeOrderId;
    receivedItems[index].type_id = constants.change_order_item_type.received;
    receivedItems[index].created_by_id = this.state.user.id;
    receivedItems[index].updated_by_id = this.state.user.id;
    receivedItems[index].converted_bill_count = (
      receivedItems[index].bill_count *
      this.state.coinConversion[
        (receivedItems[index].denominations * 100).toString()
      ]
    ).toFixed();

    const resp = await ChangeOrderItem.insertItem(receivedItems[index]);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to submit change order items',
      });
      return null;
    }

    receivedItems[index].id = resp[0].id;

    return receivedItems[index];
  }

  async updateStatus(statusKey) {
    const ChangeOrder = new ChangeOrderHeaderDataSource(this.state.token);
    const resp = await ChangeOrder.updateStatus(
      this.state.changeOrderId,
      statusKey,
      this.state.deliveryDate
    );

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit change order',
      });
      return;
    }

    this.onStatusChange(statusKey);
  }

  async updateTill(data) {
    data.tillType = constants.till_types.till;
    data.actionId = constants.action_ids.debit;
    data.actionTypeId = constants.action_type_ids.change_order;
    data.amountToDeposit = (this.getTotal(data.receivedItems) * 100).toFixed();
    data.totalAmount = (
      Number(this.state.currentAmount) +
      this.getTotal(data.receivedItems) * 100
    ).toFixed();
    data.facilityId = this.state.changeOrderHeader.facility_id;
    data.createdById = this.state.user.id;
    data.createdByName = this.state.user.username;
    data.transactionId = this.state.changeOrderId;

    console.log('edit-change-order, updateTill w/ data:', data);

    const ChangeOrder = new ChangeOrderHeaderDataSource(this.state.token);
    const resp = await ChangeOrder.insertTill(data);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to update till with new change order',
      });
      return null;
    }

    return resp[0].id;
  }

  handleReceivedChange = (name, row_id) => (event) => {
    const { receivedItems } = this.state;

    for (let i = 0; i < receivedItems.length; i++) {
      const item_row_id = receivedItems[i].row_id;
      if (row_id === item_row_id) {
        receivedItems[i][name] = event.target.value;
      }
    }

    this.setState({ receivedItems });
  };

  onBillCountChange = (name, row_id) => (event) => {
    const { receivedItems } = this.state;

    for (let i = 0; i < receivedItems.length; i++) {
      const item_row_id = receivedItems[i].row_id;
      if (row_id === item_row_id) {
        receivedItems[i][name] = parseInt(event.target.value);
        if (event.target.value < 0) receivedItems[i][name] = 0;
        if (event.target.value > 1000) return null;
        if (isNaN(parseInt(event.target.value))) receivedItems[i][name] = '';
      }
    }

    this.setState({ receivedItems });
  };

  handleClick = () => {
    this.setState({ openNotification: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openNotification: false });
  };

  render() {
    const { classes } = this.props;
    if (
      _.isEmpty(this.state.changeOrderHeader) ||
      _.isEmpty(this.state.requestedItems) ||
      !this.state.statusKey
    ) {
      return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
    }

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <form
          noValidate
          autoComplete="off"
          classes={classes.container}
          onSubmit={this.handleSubmit}
        >
          {this.state.discrepancy ? <DiscrepancyModal form={this} /> : null}
          {this.state.processingError ? <ErrorOhmModal form={this} /> : null}
          {this.state.processing ? <LoadingOhmModal form={this} /> : null}
          <ChangeOrderHeaderData
            form={this}
            classes={classes}
            status_name={status_name}
            bank_name={constants.bank_name}
          />
          <ChangeOrderItemTable
            data={this.state.requestedItems}
            title="Requested"
            classes={classes}
            denomination_options={this.state.filtered_denominations}
          />
          {!['requested', ''].includes(this.state.statusKey) ? (
            <ChangeOrderItemTable
              data={this.state.receivedItems}
              title="Received"
              classes={classes}
              denomination_options={this.state.filtered_denominations}
            />
          ) : (
            <EditTable
              form={this}
              classes={classes}
              title="Received"
              denomination_options={this.state.filtered_denominations}
              currency_options={constants.currency_options}
              disable_denomination_select={false}
              disable_count_field={false}
              request={false}
              convert
              change_order
            />
          )}
          <ChangeOrderTotalTable title="Total" form={this} classes={classes} />
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.submitButton}
              type="submit"
              children="Submit"
              disabled={this.state.submitButtonDisabled}
            >
              Submit
            </Button>
          </Grid>
        </form>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            open={this.state.openNotification}
            onClose={this.handleClose}
          >
            <SnackBarNotification
              onClose={this.handleClose}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
            />
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

Form.propTypes = {};

export default withStyles(styles)(Form);
