import apiPrefix from 'middleware/helpers/apiPrefix';

export default class TillDepositDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }

  async fetchData(tillId) {
    if (this.data.length > 0) {
      return this.data;
    }
    // console.log('TillCountHeaderDataSource: fetching adjustments...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const baseUrl = `/retail-cash/adjustment/${tillId}`;
    this.url = apiPrefix + baseUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.json();
    this.data = data;
    // console.log(data);
    return data;
  }

  async update(updateInfo) {
    console.log('TillCountHeaderDataSource: updating till deposit header...');
    const domain = process.env.OHM_DOMAIN;
    // let url;
    const baseurl = `https://${domain}/retail-cash/till-transaction-log`;
    // need to add a till vs safe id
    const transactionLogIdUrl = `/${updateInfo.depositLogId}/update`;
    const notesUrl = `/${updateInfo.notes}`;

    const url = (baseurl + transactionLogIdUrl + notesUrl);

    const resp = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }


  async insert_with_je(count) {
    console.log('TillCountHeaderDataSource: inserting till deposit with je header...');
    const url = new URL(
      `/api/v1/finance/journal/${count.stationId}/${count.tillId}/till-deposit`,
      `https://${process.env.HELIOS_RETAIL_DOMAIN}`
    );
    const endpoint = url.href;
    const payload = {
      balance_amount: count.totalAmount,
      credit_amount: count.amountToDeposit,
      creator_id: count.createdById,
      creator_name: count.createdByName,
      transaction_id: count.transactionId
    };
    const resp = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
      body: JSON.stringify(payload)
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }
}
