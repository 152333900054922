import urlstr from 'static/urlstr';

const headers = ({ token }) => ({
  Authorization: `bearer ${token}`,
});

const API = {
  async createChangeOrderJournalEntry(changeOrderId, token) {
    const endpoint = urlstr({
      host: process.env.HELIOS_RETAIL_DOMAIN,
      pathname: `/api/v1/finance/journal/${changeOrderId}/change-order`,
    });
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers({ token }),
    }).catch(() => {
      throw new Error('Failed to create journal entry.');
    });
    console.log(response);

    switch (response.status) {
      case 200:
        return true;
      case 403:
        // missing credentials, needs to log in
        return null;
      case 500:
        throw new Error('Internal Server Error.');
      default:
        // usually CORS issues
        throw new Error('Something went wrong. 😢');
    }
  },
};

export default API;
