import React from 'react';
import Select from 'react-select';

import { Paper, Typography } from '@material-ui/core';
import { Caption, HeadingPrimaryXS } from '@warbyparker/design-type';

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function TransferHeaderData(props) {
  const { classes, form, till_to_safe } = props;
  const disabled = form.state.submitButtonDisabled;
  return (
    <Paper className={classes.paperflex}>
      {till_to_safe ? (
        <div>
          <HeadingPrimaryXS>Till To Safe Transfer</HeadingPrimaryXS>
          <Caption children="How much to transfer from till?" />
        </div>
      ) : (
        <div>
          <HeadingPrimaryXS>Safe To Till Transfer</HeadingPrimaryXS>
          <Caption children="How much to transfer from safe?" />
        </div>
      )}
      <Select
        className={classes.textField}
        value={form.state.retailStore}
        options={form.state.retailStoresSelect}
        components={SingleValue}
        onChange={form.handleRetailStoreChange('retailStore')}
        placeholder="Select a Retail Store"
        disabled={disabled}
      />
    </Paper>
  );
}
export default TransferHeaderData;
