/* eslint-disable no-alert */
/* global _, alert */
import React from 'react';
import { Redirect } from 'react-router-dom';

import RetailStoresDataSource from 'middleware/retail-cash/RetailStoresDataSource';
import AdminUserDataSource from 'middleware/retail-cash/AdminUserDataSource';
import TillCountHeaderDataSource from 'middleware/retail-cash/till/TillCountHeaderDataSource';
import TillCountItemDataSource from 'middleware/retail-cash/till/TillCountItemDataSource';
import TillAdjustmentDataSource from 'middleware/retail-cash/till/TillAdjustmentDataSource';
import TillAdminCurrentAmountDataSource from 'middleware/retail-cash/till/TillAdminCurrentAmountDataSource';
import AdjJournalEntry from 'middleware/journal-entry/adjustment/CashAdjustmentHeliosJournal';
import * as constants from 'static/constants';

import TillCountHeaderData from 'containers/till-count/components/TillCountHeaderData';
import EditTable from 'containers/denominations-display/components/EditTable';
import TotalTable from 'containers/denominations-display/components/TotalTable';
import TillCountAdjustmentListTable from 'containers/till-count/components/TillCountAdjustmentListTable';

import DiscrepancyModal from 'containers/till-count/components/DiscrepancyModal';
import DiscrepancyCountModal from 'containers/till-count/components/DiscrepancyCountModal';
import SuccessModal from 'containers/till-count/components/SuccessModal';

import AuthModal from 'containers/layout/components/AuthModal';
import ErrorOhmModal from 'containers/shared/components/ErrorOhmModal';
import LoadingOhmModal from 'containers/shared/components/LoadingOhmModal';

import SnackBarNotification from 'containers/layout/components/SnackBarNotification';
import { formStyles } from 'containers/layout/components/FormStyles';
import { HeadingPrimaryXS } from '@warbyparker/design-type';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Snackbar } from '@material-ui/core';

function createData(
  row_id,
  id,
  till_count_id,
  type_id,
  denominations,
  bill_count,
  currency,
  created_by_id
) {
  return {
    row_id: row_id + 1,
    id,
    till_count_id,
    type_id,
    denominations,
    bill_count,
    currency,
    created_by_id,
  };
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tillId: null,
      stationId: null,
      adminPermission: false,
      token: props.token,
      user: props.user,
      entityId: null,
      entityName: null,
      bankKey: 'bank_of_america',
      currentAmount: 0,
      adjustmentTotal: 0,
      lastCount: 0,
      countAmount: 0,
      totalAmount: 0,
      otherTillAmount: 0,
      facilityData: null,
      facility: props.facility,
      requestedItems: [],
      denomination: 0.25,
      denomination_options: constants.usd_denomination_options,
      currentDenominations: [],
      currency: props.facility.currency,
      coinConversion: constants.usd_coin_conversion,
      openNotification: false,
      notificationMessage: null,
      variantNotification: null,
      formSuccess: false,
      redirect: false,
      submitButtonDisabled: false,
      allRetailStores: [],
      countType: null,
      tillType: { value: 1, label: 'Till' },
      // TODO: Need to fix this once safe locations exist
      tillTypeSelect: [
        { value: 1, label: 'Till' },
        { value: 2, label: 'Safe' },
      ],
      outstandingAdjustments: null,
      confirmAdjustments: false,
      retailStore: null,
      retailStoresSelect: null,
      retailStores: props.retailStores,
      permissions: props.permissions,
      discrepancy: false,
      discrepancyCount: false,
      discrepancyConfirmed: false,
      processing: false,
      processingError: false,
      processingErrorMessage: null,
      countNotes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    const adminPermission = this.props.permissions.filter(
      (x) => x === 'finance_account.view'
    );
    if (adminPermission.length > 0) {
      this.state.adminPermission = true;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.state.allRetailStores) && this.state.adminPermission) {
      await this.fetchAllRetailStores();
    }

    const defaultFacilityId = this.state.facility
      ? this.state.facility.id
      : null;

    this.selectCashLocation(constants.till_types.till);
    this.selectRetailStore(defaultFacilityId, this.state.retailStores);
    this.createEmptyItems();
  }

  onBillCountChange = (name, row_id) => (event) => {
    if (this.state.countType === null) {
      this.setState({
        openNotification: true,
        notificationMessage: 'Please select a Count Type',
        notificationVariant: 'warning',
      });
      return null;
    }

    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i += 1) {
      const item_row_id = requestedItems[i].row_id;
      requestedItems[i].currency = this.state.currency;

      if (row_id === item_row_id) {
        requestedItems[i][name] = parseInt(event.target.value, 10);
        if (event.target.value < 0) requestedItems[i][name] = 0;
        if (Number.isNaN(parseInt(event.target.value, 10))) {
          requestedItems[i][name] = '';
        }
      }
    }

    this.setState({ requestedItems });
    this.getTotal(requestedItems);
    return requestedItems;
  };

  async getCurrentBalance(store, tillType) {
    const currentAmount = new TillAdminCurrentAmountDataSource(
      this.state.token
    );
    const resp = await currentAmount.fetchData(store.value, tillType.value);

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to get till balance',
      });
    }

    if (resp[0]) {
      this.setState({
        currentAmount: resp[0].balance_amount,
        tillId: resp[0].id,
        stationId: resp[0].station_id,
      });
      this.getOutstandingAdjustments(resp[0].id, resp[0].station_id);
    }

    if (tillType.value === constants.till_types.till) {
      const otherTillAmount = await currentAmount.fetchData(store.value, 2);
      // check here
      if (otherTillAmount.length > 0) {
        this.setState({ otherTillAmount: otherTillAmount[0].balance_amount });
      }
    }
  }

  async getOutstandingAdjustments(tillId, stationId) {
    this.setState({ outstandingAdjustments: [] });
    const TillCountHeader = new TillCountHeaderDataSource(this.state.token);
    const location = {
      tillId,
      stationId,
      statusId: 3,
    };
    const resp = await TillCountHeader.fetchAdj(location);

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to get outstanding adjustments',
      });
    }

    let adjustmentTotal = 0;

    if (resp.length > 0) {
      const adjustments = resp.map((adjust) => ({
        id: adjust.id,
        amount: adjust.amount,
        notes: adjust.notes,
        person: adjust.created_by,
        reasonCode: adjust.reason_id,
        verify: true,
      }));
      this.setState({ outstandingAdjustments: adjustments });

      for (let i = 0; i < adjustments.length; i += 1) {
        adjustmentTotal += adjustments[i].amount;
      }

      await this.fetchAdminUserReason(adjustments);
    }
    this.setState({ adjustmentTotal });
  }

  getTotal(data) {
    let total = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (this.state.tillType.value === constants.till_types.till) {
        total += data[i].bill_count * data[i].denominations;
      } else {
        total +=
          data[i].bill_count *
          (data[i].denominations *
            this.state.coinConversion[
              (data[i].denominations * 100).toString()
            ]);
      }
    }

    this.setState({ totalAmount: total });
    return total;
  }

  selectRetailStore(selectedFacility, retailStores) {
    let currentStores = retailStores;

    if (this.state.adminPermission) currentStores = this.state.allRetailStores;

    const retailStoresSelect = currentStores.map((retailStore) => ({
      value: retailStore.id,
      label: retailStore.name,
      locale: retailStore.locale,
    }));

    this.setState({ retailStoresSelect });
    const defaultRetailStore = retailStoresSelect.filter(
      (x) => x.value === selectedFacility
    );
    let retailStore = retailStoresSelect[0];

    if (defaultRetailStore.length > 0) [retailStore] = defaultRetailStore;

    this.setState({ retailStore });

    const entity = constants.entity_options.filter(
      (x) => x.label === constants.country_codes_entity[retailStore.locale]
    );

    this.selectCurrency(entity[0].label);
    this.setState({ entityName: entity[0].label, entityId: entity[0].value });
    this.selectBank(entity[0].label);

    const defaultTillType = this.state.tillType ? this.state.tillType : null;
    this.getCurrentBalance(retailStore, defaultTillType);
  }

  selectCashLocation(selectedTillType) {
    let tillType = constants.till_types.till;
    const defaultCashLocation = this.state.tillTypeSelect.filter(
      (x) => x.value === selectedTillType
    );

    if (defaultCashLocation.length > 0) [tillType] = defaultCashLocation;
    this.setState({ tillType });
  }

  selectBank(entityName) {
    const bank = constants.bank_options.filter((x) => x.entity === entityName);
    this.setState({ bankKey: bank[0].value });
  }

  selectCurrency(entityName) {
    this.setState({ currency: constants.entity_default_currency[entityName] });
    const { requestedItems } = this.state;
    // was code conflict here
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (constants.entity_default_currency[entityName] === 'CAD') {
      this.setState({
        coinConversion: constants.cad_coin_conversion,
        denomination_options: constants.cad_denomination_options,
      });
      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < requestedItems.length; i += 1) {
      requestedItems[i].denominations = den_options.filter(
        (x) => x.label === denominations[i]
      )[0].value;
      requestedItems[i].currency =
        constants.entity_default_currency[entityName];
    }
    this.setState({ requestedItems });

    const currentDenominations = [];

    for (let i = 0; i < denominations.length; i += 1) {
      const denomination = den_options.filter(
        (x) => x.label === denominations[i]
      );
      currentDenominations.push(denomination[0]);
    }
    this.setState({ currentDenominations });
  }

  async fetchAllRetailStores() {
    const RetailStores = new RetailStoresDataSource(this.props.token);
    const resp = await RetailStores.fetchData();

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to get list of retail stores',
      });
    }
    this.setState({ allRetailStores: resp });
  }

  async fetchAdminUserReason(adjustments) {
    const ReasonCodes = new TillAdjustmentDataSource(this.props.token);
    const reasonResp = await ReasonCodes.fetchReasons();

    if (reasonResp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to get user who created adjustment',
      });
    }

    const reasonCodeSelect = reasonResp.map((reasonCode) => ({
      value: reasonCode.id,
      key: reasonCode.key,
      label: reasonCode.name,
    }));

    const items = [];
    for (let i = 0; i < adjustments.length; i += 1) {
      items.push(this.processAdjustment(adjustments, i, reasonCodeSelect));
    }
    await Promise.all(items);
  }

  async processAdjustment(adjustments, index, reasonCodes) {
    const currentAdjustment = adjustments[index];
    const AdminUser = new AdminUserDataSource(this.props.token);
    const resp = await AdminUser.fetchData(currentAdjustment.person);

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to get user who created adjustment',
      });
    }

    // TODO: Replace if statment with try and catch
    currentAdjustment.person = resp.username;
    const reasonCode = reasonCodes.filter(
      (x) => x.value === currentAdjustment.reasonCode
    );
    // TODO: Replace if statment with try and catch
    if (reasonCode.length > 0) {
      currentAdjustment.reasonCode = reasonCode[0].key;
    }
  }

  createEmptyItems() {
    let row_id = 1;
    const data = [];
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (this.state.currency === 'CAD') {
      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < denominations.length; i += 1) {
      const denomination = den_options.filter(
        (x) => x.label === denominations[i]
      );
      data.push(
        createData(
          row_id,
          null,
          null,
          1,
          denomination[0].value,
          '',
          this.state.currency,
          null
        )
      );
      row_id += 1;
    }
    this.setState({ requestedItems: data });
  }

  handleRetailStoreChange = (name) => (value) => {
    this.setState({ [name]: value });
    this.selectRetailStore(value.value, this.state.retailStores);
  };

  handleTillTypeChange = () => (value) => {
    this.getCurrentBalance(this.state.retailStore, value);
    this.selectCashLocation(value.value);
  };

  // TODO: Look into changing this
  handleSelectChange = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleTextFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleCofirmChange(rowIndex) {
    const tempArray = this.state.outstandingAdjustments;
    tempArray[rowIndex].verify = !tempArray[rowIndex].verify;

    let adjustmentTotal = 0;
    for (let i = 0; i < tempArray.length; i += 1) {
      if (tempArray[i].verify) adjustmentTotal += tempArray[i].amount;
    }

    this.setState({ adjustmentTotal, outstandingAdjustments: tempArray });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleRequestedChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;
    for (let i = 0; i < requestedItems.length; i += 1) {
      const item_row_id = requestedItems[i].row_id;
      if (row_id === item_row_id) requestedItems[i][name] = event.target.value;
    }
    this.setState({ requestedItems });
  };

  handleCurrencyChange = (name) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i += 1) {
      requestedItems[i][name] = event.target.value;
    }

    this.setState({ requestedItems, currency: event.target.value });
  };

  handleSubmit(event) {
    // todo: submit buttons change here
    event.preventDefault();

    if (this.state.confirmAdjustments) {
      const data = {
        stationId: this.state.stationId,
        tillId: this.state.tillId,
        adjustments: this.state.outstandingAdjustments,
        user: this.state.user.id,
        adjId: null,
        statusId: 1,
      };
      this.setState({ submitButtonDisabled: true, processing: true });
      this.submitAdjustmentsForm(data);
    } else {
      const data = {
        requestedItems: this.state.requestedItems,
        tillType: this.state.tillType.value,
        actionId: 3,
        actionTypeId: this.state.countType.value,
        totalAmount: (
          (this.getTotal(this.state.requestedItems)
            ? this.getTotal(this.state.requestedItems)
            : 0) * 100.0
        ).toFixed(),
        facilityId: this.state.retailStore.value,
        createdById: this.state.user.id,
        createdByName: this.state.user.username,
        countType: this.state.countType,
      };
      let isInsert = false;

      for (let i = 0; i < data.requestedItems.length; i += 1) {
        if (data.requestedItems[i] === '') data.requestedItems[i] = 0;

        if (data.requestedItems[i].bill_count > 0) {
          isInsert = true;
          break;
        }
      }
      // TODO: should be seperate validation function
      if (
        data.tillType === 1 &&
        isInsert &&
        data.totalAmount > 30099 &&
        this.state.otherTillAmount < 50000
      ) {
        // Convert amount from pennies
        const safeUnder = (50000 - this.state.otherTillAmount) / 100;
        // Since change cannot be in the safe only bills can be moved from till to safe
        //    i.e. No loose change
        const tillOver =
          (data.totalAmount - (data.totalAmount % 100) - 30000) / 100;
        let amountToReplenish = '0';

        if (tillOver >= safeUnder) amountToReplenish = safeUnder.toFixed(2);
        else amountToReplenish = tillOver.toFixed(2);

        alert(
          'Please replenish Safe $'
            .concat(amountToReplenish)
            .concat(' from your till and count again.')
        );

        this.setState({ requestedItems: [] });
        // maybe have this refresh page to be default safe count
        this.createEmptyItems();
        isInsert = false;
      }
      // totalAmount is a string value
      if (
        isInsert &&
        Number(data.totalAmount) !== this.state.currentAmount &&
        data.totalAmount !== this.state.lastCount
      ) {
        isInsert = false;
        // Feedback from Advisors that they want Alert Confirmation
        alert('Please count again'); // eslint-disable-line no-alert
        this.setState({ lastCount: data.totalAmount, requestedItems: [] });
        this.createEmptyItems();
      }
      // should be seperate validation function
      if (!isInsert) {
        this.setState({
          openNotification: true,
          notificationMessage: 'The Till Count is invalid',
          notificationVariant: 'warning',
        });
      } else {
        // if this.state.currentAmount != data.totalAmount then adj JE
        this.setState({
          countAmount: data.totalAmount,
          submitButtonDisabled: true,
          processing: true,
        });
        this.submitForm(data);
      }
    }
  }

  async updateCount(countData) {
    // Update count notes field
    const TillCount = new TillCountHeaderDataSource(this.state.token);
    const resp = await TillCount.updateCount(countData);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit count',
      });
      return null;
    }
    return resp[0].id;
  }

  async submitCountNotes(data) {
    const updatedCountId = await this.updateCount(data);
    const autoAdjustmentData = {
      // note id and reason
      stationId: this.state.stationId,
      adjustmentAmount: Number(
        this.state.countAmount -
          (this.state.currentAmount + this.state.adjustmentTotal)
      ),
      balanceAmount: this.state.countAmount,
      tillId: this.state.tillId,
      createdById: this.state.user.id,
      createdByName: this.state.user.username,
      reasonCode: 3,
      notes: 'System Auto Adjustment',
      adjId: 0,
      statusId: 3,
    };
    this.insertAutoAdjustment(autoAdjustmentData);

    if (updatedCountId) {
      await this.setState({
        processing: false,
        formSuccess: true,
        openNotification: true,
        notificationMessage: 'Count successfully submitted',
        notificationVariant: 'success',
      });
    }
  }

  async submitForm(data) {
    // part 2 of submit button change here
    const tillTransactionLogID = await this.insertData(data);
    this.setState({ tillTransactionLogID });
    // Need to add ability to track denomination quantites in count
    const requestedItems = await this.insertItems(data.requestedItems);

    if (requestedItems.length > 0 && tillTransactionLogID) {
      this.setState({ processing: false });
      // If there is a discrepancy and no adjustments to confirm have discrepancy window
      if (
        this.state.currentAmount + this.state.adjustmentTotal !==
        Number(this.state.countAmount)
      ) {
        this.setState({
          discrepancyCount: true,
          submitButtonDisabled: true,
        });
        return null;
      } else if (
        this.state.outstandingAdjustments.length > 0 &&
        !this.state.confirmAdjustments &&
        this.state.tillType.value === constants.till_types.till
      ) {
        this.setState({
          confirmAdjustments: true,
          formSuccess: false,
          submitButtonDisabled: false,
        });
      } else {
        this.setState({
          formSuccess: true,
          openNotification: true,
          notificationMessage: 'Count successfully submitted',
          notificationVariant: 'success',
        });
      }
    }
    // return value for async finish
    return true;
  }

  async insertData(data) {
    const TillCountHeader = new TillCountHeaderDataSource(this.state.token);
    const resp = await TillCountHeader.insert(data);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit count',
      });
      return null;
    }
    return resp[0].id;
  }

  async insertItems(requestedItems) {
    const insertedItems = [];

    for (let i = 0; i < requestedItems.length; i += 1) {
      if (!requestedItems[i].id && requestedItems[i].bill_count > 0) {
        insertedItems.push(this.insertItem(requestedItems, i));
      }
    }

    const results = await Promise.all(insertedItems);

    if (insertedItems.length > 0) this.setState({ requestedItems: results });

    return results;
  }

  async insertItem(requestedItems, index) {
    const requestedItem = requestedItems[index];
    const TillCountItem = new TillCountItemDataSource(this.state.token);

    requestedItem.till_count_id = this.state.tillTransactionLogID;
    requestedItem.type_id = this.state.countType.value;
    requestedItem.created_by = this.state.user.id;
    requestedItem.converted_bill_count = requestedItems[index].bill_count;

    if (this.state.tillType.value === constants.till_types.safe) {
      requestedItem.converted_bill_count = (
        requestedItem.bill_count *
        this.state.coinConversion[
          (requestedItem.denominations * 100).toString()
        ]
      ).toFixed();
    }

    const resp = await TillCountItem.insertItem(requestedItem);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit count',
      });
      return null;
    }
    return requestedItem;
  }

  async submitAdjustmentsForm(data) {
    if (!this.state.discrepancyConfirmed) {
      if (
        this.state.currentAmount + this.state.adjustmentTotal !==
        this.state.countAmount
      ) {
        this.setState({
          processing: false,
          discrepancy: true,
          submitButtonDisabled: true,
        });
        return null;
      }
    }
    // error with autoadjustment discrepancy...???
    const insertedAdjustments = await this.insertAdjustments(data);
    // Need to add ability to track denomination quantites in count
    // let requestedItems = await this.insertItems(data.requestedItems);
    if (insertedAdjustments.length > 0) {
      this.setState({
        processing: false,
        openNotification: true,
        notificationMessage: 'Till Adjustments confirmed',
        notificationVariant: 'success',
        formSuccess: true,
      });
    }
    // return value for async finish
    return true;
  }

  async insertAdjustments(data) {
    const insertedItems = [];
    const { adjustments } = data;

    for (let i = 0; i < adjustments.length; i += 1) {
      if (adjustments[i].id) {
        insertedItems.push(this.insertAdjustment(data, adjustments[i]));
      }
    }

    const results = await Promise.all(insertedItems);
    return results;
  }

  async insertAdjustment(data, adjustment) {
    const adjustment_date = data;
    const TillCountHeader = new TillCountHeaderDataSource(this.state.token);

    adjustment_date.adjId = adjustment.id;
    adjustment_date.statusId = adjustment.verify ? 1 : 2;

    const resp = await TillCountHeader.updateAdj(adjustment_date);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit adjustment',
      });
      return null;
    }
    // If data.statusId = 1 'i.e. confirmed' then make JE
    // this should be returned from the post as a success
    return adjustment.id;
  }

  async insertAutoAdjustment(data) {
    const adjustment_date = data;
    const TillAdjustHeader = new TillAdjustmentDataSource(this.state.token);
    const headerResp = await TillAdjustHeader.insertAdjustment(adjustment_date);

    // error inserting new count
    if (headerResp.length === 0) return null;

    const tillTransactionLogID = headerResp[0].id;
    const TillCountHeader = new TillCountHeaderDataSource(this.state.token);

    adjustment_date.adjId = tillTransactionLogID;
    adjustment_date.statusId = 1;

    const updatedResp = await TillCountHeader.updateAdj(adjustment_date);

    // error creating auto adjustment
    if (updatedResp.length === 0) return null;

    return headerResp[0].id;
  }

  async handleFormSuccess() {
    if (this.state.countType.value === 8) {
      // this creates a JE with adjustment variance if there were any in the day
      // otherwise no JE is created
      try {
        AdjJournalEntry.createCashAdjustmentJournalEntry(
          this.state.tillId,
          this.state.token
        );
      } catch (err) {
        // handle error here
      }
    }
    this.setState({ redirect: true });
  }

  handleClick = () => {
    this.setState({ openNotification: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openNotification: false });
  };

  convertDenominations(denominations) {
    if (this.state.tillType.value === constants.till_types.till) {
      return denominations;
    }
    return (
      denominations *
      this.state.coinConversion[(denominations * 100).toString()]
    );
  }

  render() {
    const { classes } = this.props;

    if (_.isEmpty(this.state.retailStoresSelect)) {
      return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
    }

    if (this.state.redirect) {
      if (
        this.state.countAmount > 30000 &&
        this.state.countType.value === 8 && // close count
        this.state.tillType.value === constants.till_types.till
      ) {
        return (
          <Redirect
            to={{
              pathname: '/till-deposit/',
              state: { state: { ...this.state, hasCloseCount: true } },
            }}
          />
        );
      }
      return <Redirect to="/" />;
    }

    // redirect to deposit page if deposit type selected
    if (this.state.countType && this.state.countType.value === 5) {
      return (
        <Redirect
          to={{
            pathname: '/till-deposit/',
            state: {
              state: { ...this.state, countAmount: this.state.currentAmount },
            },
          }}
        />
      );
    }

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <form
          autoComplete="off"
          classes={classes.container}
          onSubmit={this.handleSubmit}
        >
          {this.state.processingError ? <ErrorOhmModal form={this} /> : null}
          {this.state.processing ? <LoadingOhmModal form={this} /> : null}
          {this.state.discrepancyCount ? (
            <DiscrepancyCountModal form={this} />
          ) : null}
          {this.state.discrepancy ? <DiscrepancyModal form={this} /> : null}
          {this.state.formSuccess ? <SuccessModal form={this} /> : null}
          <TillCountHeaderData form={this} classes={classes} />
          <EditTable
            form={this}
            classes={classes}
            title="Count"
            denomination_options={this.state.currentDenominations}
            currency_options={constants.currency_options}
            disable_count_field={
              this.state.formSuccess || this.state.confirmAdjustments
            }
            disable_denomination_select
            change_order={false}
            convert={this.state.tillType.value === constants.till_types.safe}
            request
          />
          <TotalTable
            form={this}
            classes={classes}
            title="Total"
            child_name="Count"
            till_count
          />
          {this.state.confirmAdjustments ? (
            <TillCountAdjustmentListTable form={this} classes={classes} />
          ) : null}
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.submitButton}
              type="submit"
              disabled={this.state.submitButtonDisabled}
            >
              Submit Count
            </Button>
          </Grid>
        </form>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            open={this.state.openNotification}
            onClose={this.handleClose}
          >
            <SnackBarNotification
              onClose={this.handleClose}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
            />
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

export default withStyles(formStyles)(Form);
