/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
import React from 'react';
import { Redirect } from 'react-router-dom';

import RetailStoresDataSource from 'middleware/retail-cash/RetailStoresDataSource';
import ChangeOrderItemDataSource from 'middleware/retail-cash/change-order/ChangeOrderItemDataSource';
import ChangeOrderHeaderDataSource from 'middleware/retail-cash/change-order/ChangeOrderHeaderDataSource';
import ChangeOrderJournalEntry from 'middleware/journal-entry/change-order/ChangeOrderHeliosJournal';

import ChangeOrderHeaderData from 'containers/create-change-order/components/ChangeOrderHeaderData';
import EditTable from 'containers/denominations-display/components/EditTable';
import TotalTable from 'containers/denominations-display/components/TotalTable';
import * as constants from 'static/constants';

import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Snackbar } from '@material-ui/core';
import SnackBarNotification from 'containers/layout/components/SnackBarNotification';
import AuthModal from 'containers/layout/components/AuthModal';
import ErrorOhmModal from 'containers/shared/components/ErrorOhmModal';
import LoadingOhmModal from 'containers/shared/components/LoadingOhmModal';
import { HeadingPrimaryXS } from '@warbyparker/design-type';
import { formStyles } from 'containers/layout/components/FormStyles';

const status_name = [];

function createData(
  row_id,
  id,
  change_order_id,
  type_id,
  denominations,
  bill_count,
  currency,
  created_by_id,
  updated_by_id
) {
  row_id += 1;
  return {
    row_id,
    id,
    change_order_id,
    type_id,
    denominations,
    bill_count,
    currency,
    created_by_id,
    updated_by_id,
  };
}

function defaultDate() {
  return new Date().toISOString().slice(0, 10);
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminPermission: false,
      token: props.token,
      user: props.user,
      changeOrderId: null,
      entityId: null,
      entityName: null,
      bankKey: 'bank_of_america',
      bankConfirmationNumber: null,
      expectedDeliveryDate: defaultDate(),
      facility: props.facility,
      requestedItems: [],
      statusKey: 'requested',
      coinConversion: constants.usd_coin_conversion,
      filtered_denominations: constants.usd_denomination_options,
      currency: props.facility.currency,
      openNotification: false,
      notificationMessage: null,
      formSuccess: false,
      redirect: false,
      submitButtonDisabled: false,
      allRetailStores: [],
      retailStore: null,
      retailStoresSelect: null,
      retailStores: props.retailStores,
      permissions: props.permissions,
      processing: false,
      processingError: false,
      processingErrorMessage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    const adminPermission = this.props.permissions.filter(
      (x) => x === 'finance_account.view'
    );

    if (adminPermission.length > 0) this.state.adminPermission = true;
  }

  async componentDidMount() {
    if (_.isEmpty(this.state.allRetailStores) && this.state.adminPermission) {
      await this.fetchAllRetailStores();
    }

    const defaultFacilityId = this.state.facility
      ? this.state.facility.id
      : null;

    this.selectRetailStore(defaultFacilityId, this.state.retailStores);
    this.createEmptyItems();
  }

  selectRetailStore(selectedFacility, retailStores) {
    if (this.state.adminPermission) retailStores = this.state.allRetailStores;

    const retailStoresSelect = retailStores.map((retailStore) => ({
      value: retailStore.id,
      label: retailStore.name,
      locale: retailStore.locale,
    }));

    this.setState({ retailStoresSelect });

    const defaultRetailStore = retailStoresSelect.filter(
      (x) => x.value === selectedFacility
    );
    let retailStore = retailStoresSelect[0];

    if (defaultRetailStore.length > 0) [retailStore] = defaultRetailStore;

    this.setState({ retailStore });

    const entity = constants.entity_options.filter(
      (x) => x.label === constants.country_codes_entity[retailStore.locale]
    );

    this.setState({ entityName: entity[0].label, entityId: entity[0].value });
    this.selectBank(entity[0].label);
    this.selectCurrency(entity[0].label);
  }

  selectBank(entityName) {
    const bank = constants.bank_options.filter((x) => x.entity === entityName);
    this.setState({ bankKey: bank[0].value });
  }

  selectCurrency(entityName) {
    this.setState({ currency: constants.entity_default_currency[entityName] });

    const { requestedItems } = this.state;

    // was code conflict here
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (constants.entity_default_currency[entityName] === 'CAD') {
      this.setState({
        coinConversion: constants.cad_coin_conversion,
        filtered_denominations: constants.cad_denomination_options,
      });

      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    const filtered_denominations = den_options.filter((item) =>
      denominations.includes(item.label)
    );

    this.setState({ filtered_denominations });

    for (let i = 0; i < requestedItems.length; i++) {
      requestedItems[i].currency =
        constants.entity_default_currency[entityName];
      requestedItems[i].bill_count = '';
    }

    this.setState({ requestedItems });
  }

  async fetchAllRetailStores() {
    const RetailStores = new RetailStoresDataSource(this.props.token);
    const resp = await RetailStores.fetchData();

    if (resp == null) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to get retail stores',
      });
    }

    this.setState({ allRetailStores: resp });
  }

  createEmptyItems() {
    let row_id = 1;
    const data = [];
    let den_options = constants.usd_denomination_options;

    if (this.state.currency === 'CAD') {
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < 4; i++) {
      const denomination = den_options.filter(
        (x) => x.label === constants.denomination_defaults[i]
      );
      data.push(
        createData(
          row_id,
          null,
          null,
          1,
          denomination[0].value,
          '',
          this.state.currency,
          null,
          null
        )
      );
      row_id += 1;
    }

    this.setState({ requestedItems: data });
  }

  handleRetailStoreChange = (name) => (value) => {
    this.setState({ [name]: value });
    this.selectRetailStore(value.value, this.state.retailStores);
  };

  handleSelectChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  onStatusChange(statusKey) {
    if (statusKey === 'requested') {
      status_name.push({ label: 'Requested', value: 'requested' });
      status_name.push({ label: 'Canceled', value: 'canceled' });
    }
    this.setState({ statusKey });
  }

  handleSelectStatus = (name) => (event) => {
    if (event.target.value === 'canceled') {
      const requestedItems = [];
      for (let i = 0; i < this.state.requestedItems.length; i++) {
        if (this.state.requestedItems[i].id) {
          requestedItems.push(this.state.requestedItems[i]);
        }
      }

      this.setState({ requestedItems });
    }

    this.setState({ [name]: event.target.value });
  };

  handleAddItem = () => () => {
    const { requestedItems } = this.state;

    const row_id = this.state.requestedItems.length + 1;
    if (row_id > 10) return null;

    // Let's not have more than 5 rows per change order received
    requestedItems.push(
      createData(
        row_id,
        null,
        null,
        1,
        0.25,
        '',
        this.state.currency,
        null,
        null
      )
    );
    this.setState({ requestedItems });
  };

  handleRemoveItem = () => () => {
    const { requestedItems } = this.state;
    if (requestedItems.length === 1) return;

    if (!requestedItems[requestedItems.length - 1].id) {
      requestedItems.pop();
      this.setState({ requestedItems });
    }
  };

  handleRequestedChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;
    for (let i = 0; i < requestedItems.length; i++) {
      const item_row_id = requestedItems[i].row_id;

      if (row_id === item_row_id) requestedItems[i][name] = event.target.value;
    }
    this.setState({ requestedItems });
  };

  handleCurrencyChange = (name) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i++) {
      requestedItems[i][name] = event.target.value;
    }

    this.setState({ requestedItems, currency: event.target.value });
  };

  onBillCountChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;
    for (let i = 0; i < requestedItems.length; i++) {
      const item_row_id = requestedItems[i].row_id;

      if (row_id === item_row_id) {
        requestedItems[i][name] = parseInt(event.target.value);
        if (event.target.value < 0) requestedItems[i][name] = 0;
        if (isNaN(parseInt(event.target.value))) requestedItems[i][name] = '';
      }
    }
    this.setState({ requestedItems });
  };

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      requestedItems: this.state.requestedItems,
      changeOrderHeader: {
        entityId: this.state.entityId,
        bankKey: this.state.bankKey,
        bankConfirmationNumber: this.state.bankConfirmationNumber,
        statusId: constants.change_order_item_type[this.state.statusKey],
        expectedDeliveryDate: this.state.expectedDeliveryDate,
        facilityId: this.state.retailStore.value,
        createdById: this.state.user.id,
        updatedById: this.state.user.id,
      },
    };
    console.log('Submiting create change order form w/ data:', data);

    if (this.state.changeOrderId) {
      this.setState({
        openNotification: true,
        notificationMessage: 'The Change Order is already created',
        notificationVariant: 'info',
      });
      return null;
    }

    let isInsert = false;

    for (let i = 0; i < data.requestedItems.length; i++) {
      if (data.requestedItems[i] === '') data.requestedItems[i] = 0;

      if (data.requestedItems[i].bill_count > 0) {
        isInsert = true;
        break;
      }
    }

    if (!isInsert) {
      this.setState({
        openNotification: true,
        notificationMessage:
          'The Change Order needs to have a requested amount to be created',
        notificationVariant: 'warning',
      });
    } else {
      this.setState({ submitButtonDisabled: true, processing: true });
      this.submitForm(data);
    }
  }

  async submitForm(data) {
    console.log('Submit Form: Creation of Change Order', data);
    const changeOrderId = await this.insertHeader(data.changeOrderHeader);
    this.setState({ changeOrderId });

    const requestedItems = await this.insertItems(data.requestedItems);

    ChangeOrderJournalEntry.createChangeOrderJournalEntry(
      changeOrderId,
      this.state.token
    );

    if (requestedItems.length > 0 && changeOrderId) {
      await this.setState({
        processing: false,
        openNotification: true,
        notificationMessage: `Change Order: ${changeOrderId} created`,
        notificationVariant: 'success',
        formSuccess: true,
      });
    }
  }

  async insertHeader(changeOrderHeader) {
    const ChangeOrderHeader = new ChangeOrderHeaderDataSource(this.state.token);
    const resp = await ChangeOrderHeader.insert(changeOrderHeader);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage: 'Error when trying to submit change order',
      });
      return null;
    }

    return resp[0].id;
  }

  async insertItems(requestedItems) {
    const insertedItems = [];

    for (let i = 0; i < requestedItems.length; i++) {
      if (!requestedItems[i].id && requestedItems[i].bill_count > 0) {
        const requestedItem = await this.insertItem(requestedItems, i);
        insertedItems.push(requestedItem);
      }
    }

    if (insertedItems.length > 0) {
      this.setState({ requestedItems: insertedItems });
    }

    return insertedItems;
  }

  async insertItem(requestedItems, index) {
    const ChangeOrderItem = new ChangeOrderItemDataSource(this.state.token);

    requestedItems[index].change_order_id = this.state.changeOrderId;
    requestedItems[index].type_id = constants.change_order_item_type.requested;
    requestedItems[index].created_by_id = this.state.user.id;
    requestedItems[index].updated_by_id = this.state.user.id;
    requestedItems[index].converted_bill_count = (
      requestedItems[index].bill_count *
      this.state.coinConversion[
        (requestedItems[index].denominations * 100).toString()
      ]
    ).toFixed();

    const resp = await ChangeOrderItem.insertItem(requestedItems[index]);

    if (resp.length === 0) {
      this.setState({
        processingError: true,
        processingErrorMessage:
          'Error when trying to submit change order items',
      });
      return null;
    }

    requestedItems[index].id = resp[0].id;

    return requestedItems[index];
  }

  handleClick = () => {
    this.setState({ openNotification: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openNotification: false });
    if (this.state.formSuccess) this.setState({ redirect: true });
  };

  getTotal(data) {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total +=
        data[i].bill_count *
        (data[i].denominations *
          this.state.coinConversion[(data[i].denominations * 100).toString()]);
    }
    return total;
  }

  convertDenominations(denominations) {
    return (
      denominations *
      this.state.coinConversion[(denominations * 100).toString()]
    );
  }

  render() {
    const { classes } = this.props;

    if (
      _.isEmpty(this.state.retailStoresSelect) ||
      !this.state.bankKey ||
      !this.state.entityId
    ) {
      return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
    }

    if (this.state.redirect) return <Redirect to="/list-change-orders" />;

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <form
          autoComplete="off"
          classes={classes.container}
          onSubmit={this.handleSubmit}
        >
          {this.state.processingError ? <ErrorOhmModal form={this} /> : null}
          {this.state.processing ? <LoadingOhmModal form={this} /> : null}
          <ChangeOrderHeaderData
            form={this}
            classes={classes}
            bank_options={constants.bank_options}
            entity_options={constants.entity_options}
            entity_default_currency={constants.entity_default_currency}
          />
          <EditTable
            form={this}
            classes={classes}
            title="Requested"
            denomination_options={this.state.filtered_denominations}
            currency_options={constants.currency_options}
            disable_denomination_select={!!this.state.changeOrderId}
            disable_count_field={!!this.state.changeOrderId}
            convert
            change_order
            request
          />
          <TotalTable
            form={this}
            classes={classes}
            title="Total"
            child_name="Requested"
            till_count={false}
          />
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.submitButton}
              type="submit"
              children="Submit"
              disabled={this.state.submitButtonDisabled}
            >
              Submit
            </Button>
          </Grid>
        </form>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            open={this.state.openNotification}
            onClose={this.handleClose}
          >
            <SnackBarNotification
              onClose={this.handleClose}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
            />
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

export default withStyles(formStyles)(Form);
