import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Switch,
} from '@material-ui/core';
import { BodyMD, HeadingPrimaryXS } from '@warbyparker/design-type';

function TillCountAdjustmentListTable(props) {
  const { classes, form } = props;

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>Outstanding Adjustments</HeadingPrimaryXS>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <BodyMD children="Amount" />
            </TableCell>
            <TableCell>
              <BodyMD children="Reason" />
            </TableCell>
            <TableCell>
              <BodyMD children="Person" />
            </TableCell>
            <TableCell>
              <BodyMD children="Confirm" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {form.state.outstandingAdjustments.map((n, index) => (
            <TableRow key={n.id}>
              <TableCell component="th" scope="row">
                <TextField
                  id="amount"
                  className={classes.tableTextField}
                  value={(n.amount / 100).toFixed(2)}
                  disabled
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="reasonCode"
                  value={n.reasonCode}
                  className={classes.tableTextField}
                  disabled
                  helperText={n.notes}
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="person"
                  value={n.person}
                  className={classes.tableTextField}
                  disabled
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <Switch
                  id="notes"
                  checked={n.verify}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={form.handleCofirmChange.bind(form, index)}
                  value={n.notes}
                  color="primary"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default TillCountAdjustmentListTable;
