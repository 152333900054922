import React from 'react';
import Select from 'react-select';

import { Paper, Typography } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function TillAdjustHeaderData(props) {
  const { classes, form } = props;

  return (
    <Paper className={classes.paperflex}>
      <HeadingPrimaryXS>Till Adjustment Note</HeadingPrimaryXS>
      <Select
        className={classes.textField}
        value={form.state.retailStore}
        options={form.state.retailStoresSelect}
        components={SingleValue}
        onChange={form.handleRetailStoreChange('retailStore')}
        placeholder="Select a Retail Store"
      />
      <Select
        className={classes.textField}
        value={form.state.reasonCode}
        options={form.state.reasonCodeSelect}
        components={SingleValue}
        onChange={form.handleReasonCodeChange('reasonCode')}
        placeholder="Select a Reason Code"
      />
    </Paper>
  );
}

export default TillAdjustHeaderData;
