import React from 'react';
import Select from 'react-select';

import { MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ChangeOrderHeaderData(props) {
  const { classes, form, bank_options, entity_options } = props;
  const disabled = !!form.state.changeOrderId;

  return (
    <Paper className={classes.paperflex}>
      <HeadingPrimaryXS>Create Change Order</HeadingPrimaryXS>
      <Select
        className={classes.textField}
        value={form.state.retailStore}
        options={form.state.retailStoresSelect}
        components={SingleValue}
        onChange={form.handleRetailStoreChange('retailStore')}
        placeholder="Select a Retail Store"
      />
      <TextField
        id="entity-name"
        select
        label="Entity"
        className={classes.textField}
        value={form.state.entityId}
        onChange={form.handleSelectChange('entityId')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        disabled
        margin="normal"
      >
        {entity_options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="bank-name"
        select
        label="Bank Name"
        className={classes.textField}
        value={form.state.bankKey}
        onChange={form.handleSelectChange('bankKey')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        disabled={disabled}
      >
        {bank_options
          .filter((x) => x.entity === form.state.entityName)
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        required
        id="bank_confirmation_number"
        label="Bank Confirmation"
        className={classes.textField}
        onChange={form.handleSelectChange('bankConfirmationNumber')}
        margin="normal"
        InputProps={{
          className: classes.input,
        }}
        disabled={disabled}
      />
      <TextField
        id="expected_delivery_date"
        label="Expected Delivery Date"
        type="date"
        value={form.state.expectedDeliveryDate}
        onChange={form.handleSelectChange('expectedDeliveryDate')}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.input,
        }}
        required
        disabled={disabled}
      />
    </Paper>
  );
}
export default ChangeOrderHeaderData;
