import React from 'react';

import { BodyMD, HeadingPrimaryXS } from '@warbyparker/design-type';
import {
  Button,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

function EditTable(props) {
  const {
    form,
    classes,
    title,
    denomination_options,
    currency_options,
    disable_denomination_select,
    disable_count_field,
    convert,
    change_order,
    request,
  } = props;

  const items = request ? form.state.requestedItems : form.state.receivedItems;

  function get_denomination(count, denomination) {
    if (convert) {
      return (count * form.convertDenominations(denomination)).toFixed(2);
    }
    return (count * denomination).toFixed(2);
  }

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>{title}</HeadingPrimaryXS>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <BodyMD children="Denomination" />
            </TableCell>
            <TableCell>
              <BodyMD children="Count" />
            </TableCell>
            <TableCell>
              <BodyMD children="Total" />
            </TableCell>
            <TableCell>
              <BodyMD children="Currency" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((n) => (
            <TableRow key={n.row_id}>
              <TableCell component="th" scope="row">
                <TextField
                  id="denomination"
                  select
                  className={classes.tableTextField}
                  value={n.denominations}
                  onChange={
                    request
                      ? form.handleRequestedChange('denominations', n.row_id)
                      : form.handleReceivedChange('denominations', n.row_id)
                  }
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  disabled={disable_denomination_select}
                >
                  {denomination_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell>
                <TextField
                  id="bill_count"
                  value={n.bill_count}
                  onChange={form.onBillCountChange('bill_count', n.row_id)}
                  type="number"
                  inputProps={{ min: 0 }}
                  className={classes.tableTextField}
                  margin="normal"
                  disabled={disable_count_field}
                />
                {convert && n.denominations < 1 && (
                  <span className="css-tmf5ll"> rolls</span>
                )}
              </TableCell>
              <TableCell>
                <BodyMD
                  children={
                    n.bill_count
                      ? get_denomination(n.bill_count, n.denominations)
                      : 0
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="currency"
                  select
                  className={classes.tableTextField}
                  value={n.currency ? n.currency : form.state.currency}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  disabled
                >
                  {currency_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {change_order && (
        <div>
          <Button
            variant="fab"
            mini
            color="primary"
            aria-label="Add"
            className={classes.button}
            onClick={form.handleAddItem()}
            disabled={disable_count_field}
          >
            <AddIcon />
          </Button>
          <Button
            variant="fab"
            mini
            aria-label="primary"
            className={classes.deletebutton}
            onClick={form.handleRemoveItem()}
            disabled={disable_count_field}
          >
            <DeleteIcon />
          </Button>
        </div>
      )}
    </Paper>
  );
}

export default EditTable;
