import React from 'react';
import Select from 'react-select';

import { Paper, Typography } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const countTypeSelect = [
  { value: 7, label: 'Open Count' },
  { value: 8, label: 'Close Count' },
  { value: 6, label: 'Mid-Day Count' },
  { value: 5, label: 'Deposit' },
];

const tillTypeSelect = [
  { value: 1, label: 'Till' },
  { value: 2, label: 'Safe' },
];

function TillCountHeaderData(props) {
  const { classes, form } = props;
  const disabled = form.state.formSuccess || form.state.confirmAdjustments;
  return (
    <Paper className={classes.paperflex}>
      <HeadingPrimaryXS>Till Count</HeadingPrimaryXS>
      <Select
        className={classes.textField}
        value={form.state.retailStore}
        options={form.state.retailStoresSelect}
        components={SingleValue}
        onChange={form.handleRetailStoreChange('retailStore')}
        placeholder="Select a Retail Store"
        isDisabled={disabled}
      />
      <Select
        className={classes.textField}
        value={form.state.tillType}
        options={tillTypeSelect}
        components={SingleValue}
        onChange={form.handleTillTypeChange('tillType')}
        placeholder="Select a Cash Location"
        isDisabled={disabled}
      />
      <Select
        className={classes.textField}
        value={form.state.countType}
        options={countTypeSelect}
        components={SingleValue}
        onChange={form.handleSelectChange('countType')}
        placeholder="Select a Count Type"
        isDisabled={disabled}
      />
    </Paper>
  );
}
export default TillCountHeaderData;
