import apiPrefix from 'middleware/helpers/apiPrefix';

export default class ChangeOrderHeaderDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchData(changeOrderId) {
    if (this.data.length > 0) {
      return this.data;
    }
    console.log('ChangeOrderHeaderDataSource: fetching change order...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const changeOrderUrl = `/retail-cash/change-order/${changeOrderId}`;
    this.url = apiPrefix + changeOrderUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.text();
    const jsonData = JSON.parse(
      data.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')
    );
    this.data = jsonData;
    return jsonData;
  }

  async updateStatus(changeOrderId, statusKey, deliveryDate) {
    console.log('ChangeOrderHeaderDataSource: updating change order header...');
    const domain = process.env.OHM_DOMAIN;

    const baseUrl = `https://${domain}/retail-cash/change-order/edit`;
    const parametersUrl = `/${changeOrderId}/${statusKey}/${deliveryDate}`;
    const url = baseUrl + parametersUrl;
    const resp = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }

  async insert(header) {
    console.log(
      'ChangeOrderHeaderDataSource: inserting change order header...'
    );
    const domain = process.env.OHM_DOMAIN;
    const baseurl = `https://${domain}/retail-cash/change-order/create`;
    const entityIdUrl = `/${header.entityId}`;
    const exptectedDeliveryDateUrl = `/${header.expectedDeliveryDate}`;
    const facilityIdUrl = `/${header.facilityId}`;
    const bankKeyUrl = `/${header.bankKey}`;
    const bankConfirmationNumberUrl = `/${header.bankConfirmationNumber}`;
    const statusIdUrl = `/${header.statusId}`;
    const createdByIdUrl = `/${header.createdById}`;
    const updatedByIdUrl = `/${header.updatedById}`;

    const url =
      baseurl +
      entityIdUrl +
      exptectedDeliveryDateUrl +
      facilityIdUrl +
      bankKeyUrl +
      bankConfirmationNumberUrl +
      statusIdUrl +
      createdByIdUrl +
      updatedByIdUrl;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.text();
    const jsonData = JSON.parse(
      data.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')
    );
    this.data = jsonData;
    return jsonData;
  }

  async insertTill(count) {
    console.log(
      'ChangeOrderHeaderDataSource: inserting change order till update...'
    );
    const domain = process.env.OHM_DOMAIN;
    const baseurl = `https://${domain}/api/facility`;
    const facilityIdUrl = `/${count.facilityId}/retail-cash/till`;
    const tillTypeUrl = `/${count.tillType}`;
    const actionIdUrl = `/${count.actionId}`;
    const actionTypeIdUrl = `/${count.actionTypeId}`;
    const totalAmountURL = `/${count.totalAmount}`;
    const createdByIdUrl = `/${count.createdById}`;
    const createdByNameUrl = `/${count.createdByName}`;
    const depositAmountURL = `/${count.amountToDeposit}`;
    const transactionIdURL = `/${count.transactionId}/change-order`;

    const url =
      baseurl +
      facilityIdUrl +
      tillTypeUrl +
      actionIdUrl +
      actionTypeIdUrl +
      totalAmountURL +
      createdByIdUrl +
      createdByNameUrl +
      depositAmountURL +
      transactionIdURL;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }
}
