import React from 'react';
import { connect } from 'react-redux';

import { AUTH_REQUEST } from 'middleware/auth';

import { HeadingPrimaryXS } from '@warbyparker/design-type';

const LoadingPage = ({ error, dispatch, redirecting }) => {
  if (redirecting) return <HeadingPrimaryXS>Redirecting...</HeadingPrimaryXS>;
  if (error) return <HeadingPrimaryXS>Error</HeadingPrimaryXS>;

  dispatch({ type: AUTH_REQUEST });

  return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
};

const select = (state) => ({
  error: state.auth.error,
  user: state.auth.me,
  redirecting: state.auth.redirecting,
});

export default connect(select)(LoadingPage);
