import React from 'react';

import { Paper, TextField } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function TillAdjustAmount(props) {
  const { classes, form } = props;

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>Adjustment Amount</HeadingPrimaryXS>
      <TextField
        required
        value={form.state.adjustmentAmount}
        id="adjustment_amount"
        type="number"
        step="0.01"
        min="0"
        className={classes.textField}
        onChange={form.handleSelectChange('adjustmentAmount')}
        margin="normal"
        InputProps={{
          className: classes.input,
        }}
      />
    </Paper>
  );
}

export default TillAdjustAmount;
