import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReactDOM from 'react-dom';
import initMonitoring from 'monitoring';
import createSagaMiddleware from 'redux-saga';

import AuthRoute from 'middleware/auth/route';
import auth from 'middleware/auth/index';
import createHistory from 'history/createBrowserHistory';

import HomePage from 'containers/home-page';
import ListChangeOrders from 'containers/list-change-orders';
import CreateChangeOrder from 'containers/create-change-order';
import EditChangeOrder from 'containers/edit-change-order';
import TillAdjust from 'containers/till-adjustment';
import TillCount from 'containers/till-count';
import TillDeposit from 'containers/till-deposit';
import SafeToTillTransfer from 'containers/till-safe-transfer/safe-to-till-index';
import TillToSafeTransfer from 'containers/till-safe-transfer/till-to-safe-index';


const history = createHistory();

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    logger.error({ err }, 'Saga middleware error');
  },
});

const reducers = {
  auth: auth.reducer,
};

const middlewares = [routerMiddleware(history), sagaMiddleware];

const store = createStore(
  combineReducers(reducers),
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(auth.saga);

const unslashed = ({ location }) => {
  const sansSlash = location.pathname.substring(
    0,
    location.pathname.length - 1
  );
  logger.info({ path: location.pathname, sansSlash });
  return <Redirect to={sansSlash} />;
};

initMonitoring();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <AuthRoute exact path="/" component={HomePage} />
        <Route path="*/$" exact strict render={unslashed} />
        <AuthRoute
          exact
          path="/list-change-orders"
          component={ListChangeOrders}
        />
        <AuthRoute
          exact
          path="/create-change-order"
          component={CreateChangeOrder}
        />
        <AuthRoute exact path="/till-count" component={TillCount} />
        <AuthRoute exact path="/till-adjustment" component={TillAdjust} />
        <AuthRoute exact path="/till-deposit" component={TillDeposit} />
        <AuthRoute
          exact
          path="/safe-to-till-transfer"
          component={SafeToTillTransfer}
        />
        <AuthRoute
          exact
          path="/till-to-safe-transfer"
          component={TillToSafeTransfer}
        />
        <AuthRoute
          path="/edit-change-order/:changeOrderId"
          component={EditChangeOrder}
        />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);
