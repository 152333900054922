import { BrowserAgent } from '@newrelic/browser-agent/src/loaders/browser-agent';

const initMonitoring = () => {
  const env = process.env.BUILD_ENV ? process.env.BUILD_ENV : 'dev';
  const accountID = process.env.ACCOUNT_ID || '';
  const applicationID = process.env.APP_ID || '';
  const licenseKey = process.env.LICENSE_KEY || '';

  const options = {
    init: {
      loader: 'spa',
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
      // session_trace:{ enabled:false, sampling_rate:0, error_sampling_rate:0 }
      // "session_replay":{
      //   "enabled":false,
      //   "autoStart":true,
      //   "block_selector":"",
      //   "mask_text_selector":"*",
      //   "mask_all_inputs":true,
      //   "sampling_rate":0.0,
      //   "error_sampling_rate":0.0,
      //   "collect_fonts":true,
      //   "inline_images":false,
      //   "inline_stylesheet":true
      // },
    },
    info: {
      licenseKey,
      applicationID,
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      sa: 1,
    },
    loader_config: {
      accountID,
      agentID: applicationID,
      applicationID,
      licenseKey,
      trustKey: accountID,
    },
  };
  const nrAgent = new BrowserAgent(options);
  nrAgent.run();
  console.log('Newrelic Browser Agent initialized for %s', env);
};

export default initMonitoring;
