import React from 'react';
import { connect } from 'react-redux';

import { LOGOUT } from 'middleware/auth';
import Form from 'containers/till-deposit/components/Form';

import NavigationLayout from 'containers/layout/components/NavigationLayout';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

class TillDeposit extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.location.state) {
      this.state = {
        entityId: this.props.location.state.state.entityId,
        entityName: this.props.location.state.state.entityName,
        retailStoresSelect: this.props.location.state.state.retailStoresSelect,
        retailStore: this.props.location.state.state.retailStore,
        allRetailStores: this.props.location.state.state.allRetailStores,
        tillId: this.props.location.state.state.tillId,
        countAmount: this.props.location.state.state.countAmount,
        hasCloseCount: this.props.location.state.state.hasCloseCount,
        stationId: this.props.location.state.state.stationId,
      };
    } else {
      this.state = {
        entityId: null,
        entityName: null,
        retailStoresSelect: null,
        retailStore: null,
        allRetailStores: [],
        tillId: null,
        countAmount: null,
        stationId: null,
      };
    }
  }

  render() {
    console.log('depositing to tillId:', this.state.tillId);

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid>
            <Paper>
              <NavigationLayout
                token={this.props.token}
                user={this.props.user}
                onLogout={() => this.props.dispatch({ type: LOGOUT })}
                contained_view={
                  <Form
                    state={this.state}
                    props={this.props}
                    entityId={this.state.entityId}
                    entityName={this.state.entityName}
                    tillId={this.state.tillId}
                    countAmount={this.state.countAmount}
                    hasCloseCount={this.state.hasCloseCount}
                    token={this.props.token}
                    user={this.props.user}
                    allRetailStores={this.state.allRetailStores}
                    retailStore={this.state.retailStore}
                    retailStoresSelect={this.state.retailStoresSelect}
                    retailStores={this.props.retailStores}
                    stationId={this.state.stationId}
                    facility={this.props.facility}
                    permissions={this.props.permissions}
                  />
                }
              />
            </Paper>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const select = (state) => ({
  state,
  tillId: state.auth.me.tillId,
  retailStores: state.auth.me.retail_stores,
  facility: state.auth.me.facility,
  features: state.auth.me.features,
  user: state.auth.me.user,
  token: state.auth.token,
  permissions: state.auth.me.permissions,
});

export default connect(select)(TillDeposit);
