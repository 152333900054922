import urlstr from 'static/urlstr';

const parseError = async (response) => {
  const body = await response.json();
  const error = body.error || {};
  const msg500 = 'Internal Server Error.';
  const msg = error.message || msg500;

  // TODO: format json error messages
  return new Error(msg);
};

const assert = async ({ response = {} }) => {
  switch (response.status) {
    case 201:
    case 200:
      return response.json();
    case 204:
      return {};
    case 400:
      throw new Error((await response.json()).message || 'We’ve done something wrong');
    case 500:
      throw await parseError(response);
    default:
      throw new Error('Something went wrong. 😢');
  }
};


const API = {
  async getUserJwt() {
    const endpoint = urlstr({
      host: process.env.HELIOS_RETAIL_DOMAIN,
      pathname: '/api/v1/user/jwt',
    });
    const response = await fetch(endpoint, {
      credentials: 'include',
    }).catch(() => {
      throw new Error('Failed to fetch auth token.');
    });
    // not using assert() since 403 is an acceptable status;
    let data;
    switch (response.status) {
      case 200:
        data = await response.json();
        return data.token;
      case 403:
        // missing credentials, needs to log in
        return null;
      case 500:
        throw new Error('Internal Server Error.');
      default:
        // usually CORS issues
        throw new Error('Something went wrong. 😢');
    }
  },

  async getUserMe() {
    const endpoint = urlstr({
      host: process.env.HELIOS_RETAIL_DOMAIN,
      pathname: '/api/v1/user/me',
    });

    const response = await fetch(endpoint, {
      credentials: 'include', // TODO: use token
    });
    return assert({ response });
  },
};

export default API;
