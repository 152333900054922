import apiPrefix from 'middleware/helpers/apiPrefix';

export default class ChangeOrderItemDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchData(changeOrderId, typeId) {
    if (this.data.length > 0) {
      return this.data;
    }
    console.log(
      `ChangeOrderItemDataSource: fetching change order w/ id = ${changeOrderId} and type = ${typeId}`
    );
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const changeOrderUrl = `/retail-cash/change-order-items/${changeOrderId}/${typeId}`;
    this.url = apiPrefix + changeOrderUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.json();
    this.data = data;
    return data;
  }

  async insertItem(item) {
    console.log(
      'ChangeOrderItemDataSource: inserting change order item...',
      item
    );
    const domain = process.env.OHM_DOMAIN;
    const baseUrl = `https://${domain}/retail-cash/change-order-items`;
    const changeOrderIdUrl = `/${item.change_order_id}`;
    const typeIdUrl = `/${item.type_id}`;
    const denominationsUrl = `/${item.denominations}`;
    const billCountUrl = `/${item.converted_bill_count}`;
    const currencyUrl = `/${item.currency}`;
    const createdByIdUrl = `/${item.created_by_id}`;
    const updatedByIdUrl = `/${item.updated_by_id}`;
    const url =
      baseUrl +
      changeOrderIdUrl +
      typeIdUrl +
      denominationsUrl +
      billCountUrl +
      currencyUrl +
      createdByIdUrl +
      updatedByIdUrl;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.json();
    this.data = data;
    return data;
  }
}
