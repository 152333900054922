import apiPrefix from 'middleware/helpers/apiPrefix';

export default class TillAdjustmentDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchReasons() {
    if (this.data.length > 0) {
      return this.data;
    }
    // console.log('TillAdjustmentDataSource: fetching adjustment reasons...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const reasonsUrl = '/retail-cash/adjustment/reasons';
    this.url = apiPrefix + reasonsUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.json();
    this.data = data;
    // console.log(data);
    return data;
  }

  async fetchStatuses() {
    if (this.data.length > 0) {
      return this.data;
    }
    // console.log('TillAdjustmentDataSource: fetching adjustment statuses...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const reasonsUrl = '/retail-cash/adjustment/statuses';
    this.url = apiPrefix + reasonsUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.json();
    this.data = data;
    // console.log(data);
    return data;
  }

  async insertAdjustment(adjustment) {
    // Need to fix this
    // console.log('TillAdjustmentDataSource: inserting adjustment...');
    const domain = process.env.OHM_DOMAIN;
    const baseUrl = `https://${domain}/retail-cash/adjustment`;
    const stationIdUrl = `/${adjustment.stationId}`;
    const tillIdUrl = `/${adjustment.tillId}`;
    const statusUrl = `/${adjustment.statusId}`;
    const amountUrl = `/${adjustment.adjustmentAmount}`;
    const finalAmountUrl = `/${adjustment.balanceAmount}`;
    const reasonsUrl = `/${adjustment.reasonCode}`;
    const notesUrl = `/${adjustment.notes}`;
    const createdByUrl = `/${adjustment.createdById}`;
    const url =
      baseUrl +
      stationIdUrl +
      tillIdUrl +
      statusUrl +
      amountUrl +
      finalAmountUrl +
      reasonsUrl +
      notesUrl +
      createdByUrl;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    });
    const data = await resp.text();
    const jsonData = JSON.parse(
      data.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')
    );
    this.data = jsonData;
    // console.log(jsonData);
    return jsonData;
  }
}
