import React from 'react';
import { Redirect } from 'react-router-dom';

import TillAdjustHeaderData from 'containers/till-adjustment/components/TillAdjustHeaderData';
import TillAdjustAmount from 'containers/till-adjustment/components/TillAdjustAmount';
import TillAdjustNote from 'containers/till-adjustment/components/TillAdjustNote';

import RetailStoresDataSource from 'middleware/retail-cash/RetailStoresDataSource';
import TillAdjustmentDataSource from 'middleware/retail-cash/till/TillAdjustmentDataSource';
import TillAdminCurrentAmountDataSource from 'middleware/retail-cash/till/TillAdminCurrentAmountDataSource';

import { formStyles } from 'containers/layout/components/FormStyles';
import SnackBarNotification from 'containers/layout/components/SnackBarNotification';
import AuthModal from 'containers/layout/components/AuthModal';

import { HeadingPrimaryXS } from '@warbyparker/design-type';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Snackbar } from '@material-ui/core';

const styles = formStyles;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustmentAmount: 0,
      adminPermission: false,
      token: props.token,
      user: props.user,
      facility: props.facility,
      openNotification: false,
      notificationMessage: null,
      formSuccess: false,
      redirect: false,
      submitButtonDisabled: false,
      allRetailStores: [],
      till_id: null,
      station_id: null,
      tillType: 1,
      currentAmount: 0,
      reasonCode: null,
      reasonCodeSelect: null,
      reasonCodes: null,
      statusCode: null,
      retailStore: null,
      retailStoresSelect: null,
      retailStores: props.retailStores,
      permissions: props.permissions,
      notes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    const adminPermission = this.props.permissions.filter(
      (x) => x === 'finance_account.view'
    );

    if (adminPermission.length > 0) this.state.adminPermission = true;
  }

  async componentDidMount() {
    if (this.state.allRetailStores.length === 0 && this.state.adminPermission) {
      await this.fetchAllRetailStores();
    }

    await this.fetchAllReasonCodes();
    await this.fetchAllStatusCodes();

    const defaultFacilityId = this.state.facility
      ? this.state.facility.id
      : null;

    this.selectRetailStore(defaultFacilityId, this.state.retailStores);
  }

  async getCurrentBalance(store) {
    const currentAmount = new TillAdminCurrentAmountDataSource(
      this.state.token
    );

    const resp = await currentAmount.fetchData(
      store.value,
      this.state.tillType
    );

    if (resp) {
      this.setState({
        currentAmount: resp[0].balance_amount,
        till_id: resp[0].id,
        station_id: resp[0].station_id,
      });
    }
  }

  selectRetailStore(selectedFacility, retailStores) {
    let retStores = retailStores;

    if (this.state.adminPermission) retStores = this.state.allRetailStores;

    const retailStoresSelect = retStores.map((retailStore) => ({
      value: retailStore.id,
      label: retailStore.name,
      locale: retailStore.locale,
    }));

    this.setState({ retailStoresSelect });

    const defaultRetailStore = retailStoresSelect.filter(
      (x) => x.value === selectedFacility
    );
    let [retailStore] = retailStoresSelect;

    if (defaultRetailStore.length > 0) [retailStore] = defaultRetailStore;

    this.setState({ retailStore });
    this.getCurrentBalance(retailStore);
  }

  async fetchAllRetailStores() {
    const RetailStores = new RetailStoresDataSource(this.props.token);
    const resp = await RetailStores.fetchData();
    this.setState({ allRetailStores: resp });
  }

  selectReasonCode(selectedReasonCode, reasonCodes) {
    const reasonCodeSelect = reasonCodes.map((reasonCode) => ({
      value: reasonCode.id,
      key: reasonCode.key,
      label: reasonCode.name,
    }));

    this.setState({ reasonCodeSelect });

    const defaultReasonCode = reasonCodeSelect.filter(
      (x) => x.value === selectedReasonCode
    );
    let [reasonCode] = reasonCodeSelect;

    if (defaultReasonCode.length > 0) [reasonCode] = defaultReasonCode;
    this.setState({ reasonCode });
  }

  async fetchAllReasonCodes() {
    const ReasonCodes = new TillAdjustmentDataSource(this.props.token);
    const resp = await ReasonCodes.fetchReasons();

    this.setState({ reasonCodes: resp });

    const reasonCodeSelect = resp.map((reasonCode) => ({
      value: reasonCode.id,
      key: reasonCode.key,
      label: reasonCode.name,
    }));

    this.setState({ reasonCodeSelect });
  }

  async fetchAllStatusCodes() {
    const ReasonCodes = new TillAdjustmentDataSource(this.props.token);
    const resp = await ReasonCodes.fetchStatuses();
    const statusCodeSelect = resp.map((statusCode) => ({
      value: statusCode.id,
      key: statusCode.key,
      label: statusCode.name,
    }));

    // NEED TO CONFIRM that this needs to be changed in springfield
    const defaultStatusCode = statusCodeSelect.filter(
      (x) => x.key === 'pending'
    );
    let [statusCode] = statusCodeSelect;

    if (defaultStatusCode.length > 0) [statusCode] = defaultStatusCode;
    this.setState({ statusCode: statusCode.value });
  }

  handleReasonCodeChange = (name) => (value) => {
    this.setState({ [name]: value });
    this.selectReasonCode(value.value, this.state.reasonCodes);
  };

  handleRetailStoreChange = (name) => (value) => {
    this.setState({ [name]: value });
    this.selectRetailStore(value.value, this.state.retailStores);
  };

  handleSelectChange = (name) => (event) => {
    if (this.state.reasonCode === null) {
      this.setState({
        openNotification: true,
        notificationMessage: 'Please select a Reason',
        notificationVariant: 'warning',
      });
      return null;
    }

    this.setState({ [name]: event.target.value });
    return true;
  };

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      // note id and reason
      adjustmentAmount: Number(this.state.adjustmentAmount),
      balanceAmount:
        this.state.currentAmount + Number(this.state.adjustmentAmount) * 100,
      tillId: this.state.till_id,
      stationId: this.state.station_id,
      statusId: this.state.statusCode,
      createdById: this.state.user.id,
      createdByName: this.state.user.username,
      reasonCode: this.state.reasonCode ? this.state.reasonCode.value : null,
      notes: this.state.notes,
    };
    const isInsert = data.adjustmentAmount < 5.01 && data.adjustmentAmount > 0;

    if (!isInsert) {
      if (data.adjustmentAmount <= 0) {
        this.setState({
          openNotification: true,
          notificationMessage: 'The amount to adjust needs to be postive',
          notificationVariant: 'warning',
        });
      } else {
        this.setState({
          openNotification: true,
          notificationMessage:
            'The amount is too large, please submit a ticket',
          notificationVariant: 'warning',
        });
      }
    } else {
      data.adjustmentAmount = (data.adjustmentAmount * 100).toFixed();
      this.setState({ submitButtonDisabled: true });
      this.submitForm(data);
    }
  }

  async submitForm(data) {
    const tillAdjustmentLogId = await this.insertData(data);

    if (data.adjustmentAmount > 0) {
      await this.setState({
        openNotification: true,
        notificationMessage: `Till Adjustment Log: ${tillAdjustmentLogId} `,
        notificationVariant: 'success',
        formSuccess: true,
      });
    }
  }

  async insertData(data) {
    const TillAdjustHeader = new TillAdjustmentDataSource(this.state.token);
    const resp = await TillAdjustHeader.insertAdjustment(data);
    if (resp.length === 0) return null;
    return resp[0].id;
  }

  handleClick = () => {
    this.setState({ openNotification: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openNotification: false });
    if (this.state.formSuccess) this.setState({ redirect: true });
  };

  render() {
    const { classes } = this.props;

    if (
      this.state.retailStoresSelect === null ||
      this.state.reasonCodeSelect === null
    ) {
      return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
    }

    if (this.state.redirect) return <Redirect to="/" />;

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <form
          autoComplete="off"
          classes={classes.container}
          onSubmit={this.handleSubmit}
        >
          <TillAdjustHeaderData
            form={this}
            classes={classes}
            retailStore={this.state.retailStore}
            retailStores={this.state.retailStores}
          />
          <TillAdjustAmount form={this} classes={classes} />
          <TillAdjustNote form={this} classes={classes} title="Notes" />
          <Grid container className={classes.grid}>
            <Grid item xs>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={this.state.submitButtonDisabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            open={this.state.openNotification}
            onClose={this.handleClose}
          >
            <SnackBarNotification
              onClose={this.handleClose}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
            />
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(Form);
