import React from 'react';

import { HeadingPrimarySM, BodyLG } from '@warbyparker/design-type';
import { Button, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
});

class ErrorOhmModal extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    this.state = {
      form,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  async handleClose() {
    await this.state.form.setState({
      processingError: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.form.state.processingError}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <HeadingPrimarySM>
            There was an error. Please submit a ticket.
          </HeadingPrimarySM>
          <BodyLG>{this.state.form.state.processingErrorMessage}</BodyLG>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={this.handleClose}
            className={classes.button}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ErrorOhmModal);
