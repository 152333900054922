import apiPrefix from 'middleware/helpers/apiPrefix';

export default class AdminUserDataSource {
  constructor(token, data = []) {
    this.token = token;
    this.data = data;
    this.url = null;
  }
  async fetchData(adminId) {
    if (this.data.length > 0) {
      return this.data;
    }
    console.log('RetailStoresDataSource: fetching Retail Stores...');
    const opts = {
      headers: {
        Authorization: `bearer ${this.token.replace(/_/g, 'underscore')}`,
      },
    };
    const changeOrderUrl = `/api/admin-user/${adminId}`;
    this.url = apiPrefix + changeOrderUrl;
    const resp = await fetch(this.url, opts);
    if (!resp.ok) {
      return null;
    }
    const data = await resp.json();
    this.data = data;
    console.log(data[0]);
    return data[0];
  }
}
