/* eslint-disable import/prefer-default-export */
export const formStyles = (theme) => ({
  root: {
    marginLeft: theme.spacing.unit * 20,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit - 10,
    overflowX: 'auto',
    alignItems: 'center',
    width: '65%',
  },
  paperflex: {
    marginLeft: theme.spacing.unit * 20,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit - 10,
    overflowX: 'flex',
    alignItems: 'center',
    width: '65%',
  },
  container: {
    padding: theme.spacing.unit,
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 250,
  },
  tableTextField: {
    width: 120,
  },
  menu: {
    width: 50,
  },
  table: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00A2E1',
    '&:hover': {
      background: '#0089BF',
    },
  },
  deletebutton: {
    margin: theme.spacing.unit,
    backgroundColor: '#F8F8F8',
  },
  grid: {
    marginLeft: theme.spacing.unit * 20,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: '65%',
    marginTop: theme.spacing.unit - 10,
    overflowX: 'flex',
  },
  submitButton: {
    marginLeft: 'auto',
    alignItems: 'right',
    display: 'flex',
    backgroundColor: '#009ADE',
    '&:hover': {
      background: '#0089BF',
    },
  },
  depositButton: {
    marginRight: 'auto',
    alignItems: 'left',
    display: 'flex',
    backgroundColor: '#009ADE',
    '&:hover': {
      background: '#0089BF',
    },
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
  totalsubgrid: {
    display: 'inline-block',
    padding: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit - 40,
    marginBottom: theme.spacing.unit - 50,
  },
  totalgrid: {
    marginLeft: theme.spacing.unit * 40,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit - 10,
    overflowX: 'auto',
    width: '50%',
  },
});
