export const entity_options = [
  { label: 'JAND', value: 1 },
  { label: 'WPCA', value: 2 },
];

export const entity_default_currency = {
  JAND: 'USD',
  WPCA: 'CAD',
};

export const country_codes_entity = {
  US: 'JAND',
  CA: 'WPCA',
};

export const bank_name = {
  bank_of_america: 'Bank Of America',
  rbc: 'RBC',
};

export const bank_options = [
  { label: 'Bank Of America', value: 'bank_of_america', entity: 'JAND' },
  { label: 'RBC', value: 'rbc', entity: 'WPCA' },
];

export const status_type = {
  requested: 'Requested',
};

export const change_order_item_type = {
  requested: 1,
  received: 2,
  canceled: 3,
};

export const currency_options = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
];

// conversion variables are for counting rolls of coins
// e.g., 1 roll of quarters has 40 coins, so, 40 * $.25 = $10
export const cad_coin_conversion = {
  10000: 1,
  5000: 1,
  2000: 1,
  1000: 1,
  500: 1,
  200: 25,
  100: 25,
  25: 40,
  10: 50,
  5: 40,
};

export const usd_coin_conversion = {
  10000: 1,
  5000: 1,
  2000: 1,
  1000: 1,
  500: 1,
  100: 1,
  25: 40,
  10: 50,
  5: 40,
  1: 50,
};

export const usd_denomination_options = [
  {
    value: 100,
    label: 'Hundreds',
  },
  {
    value: 50,
    label: 'Fifties',
  },
  {
    value: 20,
    label: 'Twenties',
  },
  {
    value: 10,
    label: 'Tens',
  },
  {
    value: 5,
    label: 'Fives',
  },
  {
    value: 1.0,
    label: 'Ones',
  },
  {
    value: 0.25,
    label: 'Quarters',
  },
  {
    value: 0.1,
    label: 'Dimes',
  },
  {
    value: 0.05,
    label: 'Nickels',
  },
  {
    value: 0.01,
    label: 'Pennies',
  },
];

export const cad_denomination_options = [
  {
    value: 100,
    label: 'Hundreds',
  },
  {
    value: 50,
    label: 'Fifties',
  },
  {
    value: 20,
    label: 'Twenties',
  },
  {
    value: 10,
    label: 'Tens',
  },
  {
    value: 5,
    label: 'Fives',
  },
  {
    value: 2.0,
    label: 'Toonies',
  },
  {
    value: 1.0,
    label: 'Loonies',
  },
  {
    value: 0.25,
    label: 'Quarters',
  },
  {
    value: 0.1,
    label: 'Dimes',
  },
  {
    value: 0.05,
    label: 'Nickels',
  },
  {
    value: 0.01,
    label: 'Pennies',
  },
];

export const denomination_defaults = ['Fives', 'Quarters', 'Dimes', 'Nickels'];

export const usd_denominations = [
  'Hundreds',
  'Fifties',
  'Twenties',
  'Tens',
  'Fives',
  'Ones',
  'Quarters',
  'Dimes',
  'Nickels',
  'Pennies',
];

export const cad_denominations = [
  'Hundreds',
  'Fifties',
  'Twenties',
  'Tens',
  'Fives',
  'Toonies',
  'Loonies',
  'Quarters',
  'Dimes',
  'Nickels',
];

export const till_types = {
  till: 1,
  safe: 2,
};

export const action_ids = {
  credit: 1,
  debit: 2,
};

// add more constants here... search "actiontypeid" to find others
export const action_type_ids = {
  transfer: 2,
  deposit: 5,
  change_order: 9,
};

// Loomis safe facility ids
// Should remove this and get it elsewhere once we can
export const loomis_store = [
  680,
];
