import React from 'react';

import * as constants from 'static/constants';

import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { BodyMD, HeadingPrimaryXS } from '@warbyparker/design-type';

function coin_convert(denominations, currency) {
  let conversion = constants.usd_coin_conversion;
  if (currency === 'CAD') conversion = constants.cad_coin_conversion;
  return denominations * conversion[(denominations * 100).toString()];
}

function ChangeOrderItemTable(props) {
  const { data, classes, title, denomination_options } = props;
  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>{title}</HeadingPrimaryXS>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <BodyMD children="Denomination" />
            </TableCell>
            <TableCell>
              <BodyMD children="Count" />
            </TableCell>
            <TableCell>
              <BodyMD children="Total" />
            </TableCell>
            <TableCell>
              <BodyMD children="Currency" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => (
            <TableRow key={n.id}>
              <TableCell>
                <TextField
                  id="denomination"
                  select
                  className={classes.tableTextField}
                  value={n.denominations}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  disabled
                >
                  {denomination_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell>
                <BodyMD
                  children={
                    n.denominations < 1 ? `${n.bill_count} rolls` : n.bill_count
                  }
                />
              </TableCell>
              <TableCell>
                <BodyMD
                  children={(
                    n.bill_count * coin_convert(n.denominations, n.currency)
                  ).toFixed(2)}
                />
              </TableCell>
              <TableCell>
                <BodyMD children={n.currency} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ChangeOrderItemTable;
