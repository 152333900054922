import { put, takeLatest } from 'redux-saga/effects';
import urlstr from 'static/urlstr';
import RetailService from 'service-clients/helios-retail';

export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REDIRECTING = 'REDIRECTING';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';

const initialState = {
  token: null,
  error: null,
};

export function* fetchTokenOrRedirect() {
  let token;
  try {
    token = yield RetailService.getUserJwt();
  } catch (err) {
    logger.error({ err }, 'Auth Failure');
    yield put({ type: AUTH_FAILURE, payload: err.message });
    return;
  }

  if (token) {
    yield put({ type: TOKEN_SUCCESS, payload: token });
    return;
  }

  yield put({ type: LOGIN });
}

function* fetchMe() {
  let me;
  try {
    me = yield RetailService.getUserMe();
  } catch (err) {
    logger.error({ err }, 'Auth Failure');
    yield put({ type: AUTH_FAILURE, payload: err.message });
    return;
  }
  yield put({ type: AUTH_SUCCESS, payload: me });
}

function* goToLogin() {
  window.location = urlstr({
    host: process.env.HELIOS_RETAIL_DOMAIN,
    pathname: 'login',
    query: { next: window.location.href },
  });
  yield put({ type: REDIRECTING });
}

function* goToLogout() {
  window.location = urlstr({
    host: process.env.HELIOS_RETAIL_DOMAIN,
    pathname: 'logout',
    query: { next: window.location.origin },
  });
  yield put({ type: REDIRECTING });
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOKEN_SUCCESS:
      return { ...state, token: payload, error: null };
    case AUTH_FAILURE:
      return { ...state, token: null, error: payload };
    case REDIRECTING:
      return { ...state, redirecting: true };
    case AUTH_SUCCESS:
      return { ...state, me: payload };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(LOGIN, goToLogin);
  yield takeLatest(LOGOUT, goToLogout);
  yield takeLatest(AUTH_REQUEST, fetchTokenOrRedirect);
  yield takeLatest(TOKEN_SUCCESS, fetchMe);
}

export default {
  reducer,
  saga,
};
