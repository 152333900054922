/* eslint-disable no-alert */
/* eslint-disable no-undef */
import React from 'react';
import { Redirect } from 'react-router-dom';

import TransferHeaderData from 'containers/till-safe-transfer/components/TransferHeaderData';
import EditTable from 'containers/denominations-display/components/EditTable';
import TotalTable from 'containers/denominations-display/components/TotalTable';

import TillAdminCurrentAmountDataSource from 'middleware/retail-cash/till/TillAdminCurrentAmountDataSource';
import RetailStoresDataSource from 'middleware/retail-cash/RetailStoresDataSource';
import TillTransferDataSource from 'middleware/retail-cash/till/TillTransferDataSource';
import * as constants from 'static/constants';

import SnackBarNotification from 'containers/layout/components/SnackBarNotification';
import AuthModal from 'containers/layout/components/AuthModal';
import { formStyles } from 'containers/layout/components/FormStyles';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function createData(
  row_id,
  id,
  change_order_id,
  type_id,
  denominations,
  bill_count,
  currency,
  created_by_id,
  updated_by_id
) {
  row_id += 1;
  return {
    row_id,
    id,
    change_order_id,
    type_id,
    denominations,
    bill_count,
    currency,
    created_by_id,
    updated_by_id,
  };
}

function getTotal(data) {
  let total = 0;
  for (let i = 0; i < data.length; i += 1) {
    total += data[i].bill_count * data[i].denominations;
  }
  return total;
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminPermission: false,
      token: props.token,
      user: props.user,
      entityId: null,
      bankKey: 'bank_of_america',
      facility: props.facility,
      requestedItems: [],
      currency: props.facility.currency,
      coinConversion: constants.usd_coin_conversion,
      filtered_denominations: constants.usd_denomination_options,
      openNotification: false,
      notificationMessage: null,
      formSuccess: false,
      redirect: false,
      submitButtonDisabled: false,
      allRetailStores: [],
      retailStore: null,
      retailStoresSelect: null,
      retailStores: props.retailStores,
      permissions: props.permissions,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTotal = getTotal;

    const adminPermission = this.props.permissions.filter(
      (x) => x === 'finance_account.view'
    );

    if (adminPermission.length > 0) {
      this.state.adminPermission = true;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.state.allRetailStores) && this.state.adminPermission) {
      await this.fetchAllRetailStores();
    }

    const defaultFacilityId = this.state.facility
      ? this.state.facility.id
      : null;

    this.selectRetailStore(defaultFacilityId, this.state.retailStores);
    this.createEmptyItems();
  }

  async getCurrentBalance(store) {
    const currentAmount = new TillAdminCurrentAmountDataSource(
      this.state.token
    );

    const tillResp = await currentAmount.fetchData(
      store.value,
      constants.till_types.till
    );
    const safeResp = await currentAmount.fetchData(
      store.value,
      constants.till_types.safe
    );
    const till = tillResp[0];
    const safe = safeResp[0];

    console.log(
      `${till.display_name} ===> till balance = ${till.balance_amount}, safe balance = ${safe.balance_amount}`
    );

    if (till) this.setState({ tillCurrentAmount: till.balance_amount });
    if (safe) this.setState({ safeCurrentAmount: safe.balance_amount });
  }

  selectRetailStore(selectedFacility, retailStores) {
    if (this.state.adminPermission) retailStores = this.state.allRetailStores;

    const retailStoresSelect = retailStores.map((retailStore) => ({
      value: retailStore.id,
      label: retailStore.name,
      locale: retailStore.locale,
    }));

    this.setState({ retailStoresSelect });

    const defaultRetailStore = retailStoresSelect.filter(
      (x) => x.value === selectedFacility
    );

    let retailStore = retailStoresSelect[0];

    if (defaultRetailStore.length > 0) [retailStore] = defaultRetailStore;

    this.setState({ retailStore });

    const entity = constants.entity_options.filter(
      (x) => x.label === constants.country_codes_entity[retailStore.locale]
    );

    this.setState({ entityId: entity[0].value });
    this.selectBank(entity[0].label);
    this.selectCurrency(entity[0].label);
    this.getCurrentBalance(retailStore);
  }

  selectBank(entityName) {
    const bank = constants.bank_options.filter((x) => x.entity === entityName);
    this.setState({ bankKey: bank[0].value });
  }

  selectCurrency(entityName) {
    this.setState({ currency: constants.entity_default_currency[entityName] });
    const { requestedItems } = this.state;
    // was code conflict here
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (constants.entity_default_currency[entityName] === 'CAD') {
      this.setState({
        coinConversion: constants.cad_coin_conversion,
        filtered_denominations: constants.cad_denomination_options,
      });
      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    const filtered_denominations = den_options.filter((item) =>
      denominations.includes(item.label)
    );

    this.setState({ filtered_denominations });

    for (let i = 0; i < requestedItems.length; i += 1) {
      requestedItems[i].denominations = den_options.filter(
        (x) => x.label === denominations[i]
      )[0].value;
      requestedItems[i].currency =
        constants.entity_default_currency[entityName];
    }

    this.setState({ requestedItems });
  }

  async fetchAllRetailStores() {
    const RetailStores = new RetailStoresDataSource(this.props.token);
    const resp = await RetailStores.fetchData();

    this.setState({
      allRetailStores: resp,
    });
  }

  createEmptyItems() {
    let row_id = 1;
    const data = [];
    let denominations = constants.usd_denominations;
    let den_options = constants.usd_denomination_options;

    if (this.state.currency === 'CAD') {
      denominations = constants.cad_denominations;
      den_options = constants.cad_denomination_options;
    }

    for (let i = 0; i < denominations.length; i += 1) {
      const denomination = den_options.filter(
        (x) => x.label === denominations[i]
      );
      data.push(
        createData(
          row_id,
          null,
          null,
          1,
          denomination[0].value,
          '',
          this.state.currency,
          null
        )
      );
      row_id += 1;
    }

    this.setState({ requestedItems: data });
  }

  handleRetailStoreChange = (name) => (value) => {
    this.setState({ [name]: value });
    this.selectRetailStore(value.value, this.state.retailStores);
  };

  handleSelectChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleRequestedChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i++) {
      const item_row_id = requestedItems[i].row_id;
      if (row_id === item_row_id) {
        requestedItems[i][name] = event.target.value;
      }
    }

    this.setState({ requestedItems });
  };

  handleCurrencyChange = (name) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i += 1) {
      requestedItems[i][name] = event.target.value;
    }

    this.setState({ requestedItems, currency: event.target.value });
  };

  onBillCountChange = (name, row_id) => (event) => {
    const { requestedItems } = this.state;

    for (let i = 0; i < requestedItems.length; i += 1) {
      const item_row_id = requestedItems[i].row_id;
      requestedItems[i].currency = this.state.currency;

      if (row_id === item_row_id) {
        requestedItems[i][name] = parseInt(event.target.value);

        if (event.target.value < 0) {
          requestedItems[i][name] = 0;
        }

        if (isNaN(parseInt(event.target.value))) {
          requestedItems[i][name] = '';
        }
      }
    }

    this.setState({ requestedItems });
  };

  async handleSubmit(event) {
    event.preventDefault();

    const data = {
      requestedItems: this.state.requestedItems,
      tillType: constants.till_types.till,
      actionId: constants.action_ids.credit,
      actionTypeId: constants.action_type_ids.transfer,
      amountToDeposit:
        (this.getTotal(this.state.requestedItems)
          ? this.getTotal(this.state.requestedItems)
          : 0) * 100.0,
      facilityId: this.state.retailStore.value,
      createdById: this.state.user.id,
      createdByName: this.state.user.username,
      newBalanceAmount: 0,
      transactionId: this.state.retailStore.value,
    };

    let isInsert = false;
    for (let i = 0; i < data.requestedItems.length; i++) {
      if (data.requestedItems[i] === '') data.requestedItems[i] = 0;

      if (data.requestedItems[i].bill_count > 0) {
        isInsert = true;
        break;
      }
    }

    if (data.amountToDeposit > this.state.tillCurrentAmount) {
      isInsert = false;
      alert('Cannot transfer more money than in the till');
      this.setState({ requestedItems: [] });
      this.createEmptyItems();
    }

    if (!isInsert) {
      this.setState({
        openNotification: true,
        notificationMessage: 'The input values are invalid',
        notificationVariant: 'warning',
      });
    } else {
      data.newBalanceAmount =
        this.state.tillCurrentAmount - data.amountToDeposit;
      console.log(
        `updated till balance... ${this.state.tillCurrentAmount} – ${data.amountToDeposit} = ${data.newBalanceAmount}`
      );

      await this.setState({
        submitButtonDisabled: true,
        tillCurrentAmount: data.newBalanceAmount,
      });

      this.submitForm(data);
    }
  }

  async submitForm(data) {
    console.log('Submitting till->safe transfer form w/ data:', data);
    const tillTransferId = await this.insert(data);

    // Need to have submit once safe is complete
    if (tillTransferId) {
      await this.setState({
        openNotification: true,
        notificationMessage: `Transfer #${tillTransferId} created`,
        notificationVariant: 'success',
        formSuccess: true,
      });
    }
  }

  async insert(data) {
    const TillTransfer = new TillTransferDataSource(this.state.token);
    const tillResp = await TillTransfer.insertTill(data);

    if (tillResp.length === 0) {
      console.log('error inserting till transfer entry:', tillResp);
      return null;
    }

    await this.insertItems(tillResp[0].id, data.tillType, data.requestedItems);

    data.tillType = constants.till_types.safe;
    data.actionId = constants.action_ids.debit;
    data.newBalanceAmount = this.state.safeCurrentAmount + data.amountToDeposit;

    console.log(
      `updated safe balance... ${this.state.safeCurrentAmount} + ${data.amountToDeposit} = ${data.newBalanceAmount}`
    );

    this.setState({ safeCurrentAmount: data.newBalanceAmount });
    const safeResp = await TillTransfer.insertSafe(data);

    if (safeResp.length === 0) {
      console.log('error inserting safe transfer entry:', safeResp);
      return null;
    }

    await this.insertItems(safeResp[0].id, data.tillType, data.requestedItems);

    return safeResp[0].id;
  }

  async insertItems(id, type_id, requestedItems) {
    const insertedItems = [];

    for (let i = 0; i < requestedItems.length; i++) {
      if (!requestedItems[i].id && requestedItems[i].bill_count > 0) {
        const requestedItem = await this.insertItem(
          id,
          type_id,
          requestedItems,
          i
        );
        insertedItems.push(requestedItem);
      }
    }

    if (insertedItems.length > 0) {
      this.setState({ requestedItems: insertedItems });
    }

    return insertedItems;
  }

  async insertItem(id, type_id, requestedItems, index) {
    const ChangeOrderItem = new TillTransferDataSource(this.state.token);

    requestedItems[index].till_count_id = id;
    requestedItems[index].type_id = type_id;
    requestedItems[index].created_by = this.state.user.id;
    requestedItems[index].converted_bill_count =
      requestedItems[index].bill_count;

    const resp = await ChangeOrderItem.insertItem(requestedItems[index]);

    if (resp.length === 0) {
      console.log('error creating change order received item:', resp);
      return null;
    }

    requestedItems[index].id = resp[0].id;

    return requestedItems[index];
  }

  handleClick = () => {
    this.setState({ openNotification: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    this.setState({ openNotification: false });

    if (this.state.formSuccess) {
      this.setState({ redirect: true });
    }
  };

  convertDenominations(denominations) {
    return (
      denominations *
      this.state.coinConversion[(denominations * 100).toString()]
    );
  }

  render() {
    const { classes } = this.props;

    if (
      _.isEmpty(this.state.retailStoresSelect) ||
      !this.state.bankKey ||
      !this.state.entityId
    ) {
      return <HeadingPrimaryXS>Loading...</HeadingPrimaryXS>;
    }

    if (this.state.redirect) return <Redirect to="/" />;

    return (
      <Grid>
        <AuthModal
          retailStores={this.state.retailStores}
          permissions={this.state.permissions}
        />
        <form
          autoComplete="off"
          classes={classes.container}
          onSubmit={this.handleSubmit}
        >
          <TransferHeaderData form={this} classes={classes} till_to_safe />
          <EditTable
            form={this}
            classes={classes}
            title="Requested"
            denomination_options={this.state.filtered_denominations}
            currency_options={constants.currency_options}
            disable_denomination_select
            disable_count_field={this.state.submitButtonDisabled}
            change_order={false}
            convert={false}
            request
          />
          <TotalTable
            form={this}
            classes={classes}
            title="Total"
            child_name="Requested"
            till_count={false}
          />
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.submitButton}
              type="submit"
              children="Submit"
              disabled={this.state.submitButtonDisabled}
            >
              Submit
            </Button>
          </Grid>
        </form>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            open={this.state.openNotification}
            onClose={this.handleClose}
          >
            <SnackBarNotification
              onClose={this.handleClose}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
            />
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

export default withStyles(formStyles)(Form);
