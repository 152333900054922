import React from 'react';

import { MenuItem, Paper, TextField } from '@material-ui/core';
import { HeadingPrimaryXS } from '@warbyparker/design-type';

function ChangeOrderHeaderData(props) {
  const { classes, form, status_name, bank_name } = props;

  return (
    <Paper className={classes.root}>
      <HeadingPrimaryXS>Edit Change Order</HeadingPrimaryXS>
      <TextField
        id="change_order_id"
        label="Change Order ID"
        defaultValue={form.state.changeOrderHeader.id}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="bank_name"
        label="Bank Name"
        defaultValue={bank_name[form.state.changeOrderHeader.bank_name]}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="bank_confirmation_number"
        label="Bank Confirmation"
        defaultValue={form.state.changeOrderHeader.bank_confirmation_number}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="created_by"
        label="Created By"
        defaultValue={form.state.changeOrderHeader.create_username}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="created"
        label="Created"
        type="datetime"
        defaultValue={form.convertUTCDateToLocalDate(
          new Date(form.state.changeOrderHeader.created)
        )}
        className={classes.textField}
        disabled
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="updated_by"
        label="Last Updated By"
        defaultValue={form.state.changeOrderHeader.update_username}
        className={classes.textField}
        margin="normal"
        disabled
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="updated"
        label="Updated"
        type="datetime"
        defaultValue={form.convertUTCDateToLocalDate(
          new Date(form.state.changeOrderHeader.updated)
        )}
        className={classes.textField}
        disabled
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          className: classes.input,
        }}
      />
      <TextField
        id="select-status"
        select
        label="Change Order Status"
        className={classes.textField}
        value={form.state.statusKey}
        onChange={form.handleSelectStatus('statusKey')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
      >
        {status_name.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="delivery_date"
        label="Delivery Date"
        type="date"
        value={form.state.deliveryDate}
        onChange={form.handleSelectChange('deliveryDate')}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.input,
        }}
        required
      />
    </Paper>
  );
}

export default ChangeOrderHeaderData;
